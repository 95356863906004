// ***************************************************************   All Icons Import  ***************************************************************
import { dashboards } from "./dashboards";
import { FaGlobeAmericas } from "react-icons/fa";
import {
  FaChartLine,
  FaBook,
  FaDatabase,
  FaChartBar,
  FaPuzzlePiece,
  FaMap,
} from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { LuGoal } from "react-icons/lu";

// ***************************************************************   All Industries  ***************************************************************
export const refresh2024 = {
  title: "2024 Refresh",
  slug: "/2024-refresh",
  name: "refresh2024",
};

export const deployment2023 = {
  title: "2023 Deployment",
  slug: "/2023-deployment",
  name: "deployment2023",
};

// ***************************************************************   All Solutions  ***************************************************************
// Market Opportunity Generator
export const marketOpportunityGenerator = {
  title: "Market Opportunity Generator",
  slug: "/market-opportunity-generator",
  icon: <FaGlobeAmericas />,
  deployments: [refresh2024, deployment2023],
  refresh2024: [
    {
      title: "Market Overview",
      slug: "/market-overview",
      icon: <FaGlobeAmericas />,
      description:
        "Market size, CUF sales, and wallet share for relevant CUF products by each metropolitan statistical area (MSA)",
      dashboards: { ...dashboards.marketOpportunityGenerator.marketOverview },
    },
    {
      title: "CUF Membership Stats",
      slug: "/cuf-membership-stats",
      icon: <FaChartLine />,
      description:
        "Factbase of CUF membership, parishes, historical grants spends and other performance metrics",
      dashboards: {
        ...dashboards.marketOpportunityGenerator.cufMembershipStats,
      },
    },
    {
      title: "CUF Sales Regions",
      slug: "/cuf-sales-regions",
      icon: <FaPuzzlePiece />,
      description:
        "Current CUF sales regions, teams, and rep assignments by zip code",
      dashboards: {
        ...dashboards.marketOpportunityGenerator.cufSalesRegions,
      },
    },
    {
      title: "CUF Sales Opportunity",
      slug: "/cuf-sales-opportunity",
      icon: <FaChartBar />,
      description:
        "Estimate of incremental sales and agent recruiting requirements to grow in underpenetrated markets",
      dashboards: {
        ...dashboards.marketOpportunityGenerator.cufSalesOpportunity,
      },
    },
    {
      title: "Data for Export",
      slug: "/data-for-export",
      icon: <FaDatabase />,
      description:
        "Exportable tables comprising data in market overview, membership stats, and sales opportunity at msa level, with parish list by county",
      dashboards: { ...dashboards.marketOpportunityGenerator.dataForExport },
    },
    {
      title: "User Guide",
      slug: "/user-guide",
      icon: <FaBook />,
      description:
        "Instruction for using and interpreting the dashboards on the portal",
      pdf: "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/catholic_united/MOG_User_Guide-Catholic_United_Financial_2024refresh.pdf",
    },
  ],
  deployment2023: [
    {
      title: "Market Overview",
      slug: "/market-overview",
      icon: <FaGlobeAmericas />,
      description:
        "Market size, CUF sales, and wallet share for relevant CUF products by each metropolitan statistical area (MSA)",
      dashboards: { ...dashboards.marketOpportunityGenerator.marketOverview },
    },
    {
      title: "CUF Membership Stats",
      slug: "/cuf-membership-stats",
      icon: <FaChartLine />,
      description:
        "Factbase of CUF membership, parishes, historical grants spends and other performance metrics",
      dashboards: {
        ...dashboards.marketOpportunityGenerator.cufMembershipStats,
      },
    },
    {
      title: "CUF Sales Regions",
      slug: "/cuf-sales-regions",
      icon: <FaPuzzlePiece />,
      description:
        "Current CUF sales regions, teams, and rep assignments by zip code",
      dashboards: {
        ...dashboards.marketOpportunityGenerator.cufSalesRegions,
      },
    },
    {
      title: "CUF Sales Opportunity",
      slug: "/cuf-sales-opportunity",
      icon: <FaChartBar />,
      description:
        "Estimate of incremental sales and agent recruiting requirements to grow in underpenetrated markets",
      dashboards: {
        ...dashboards.marketOpportunityGenerator.cufSalesOpportunity,
      },
    },
    {
      title: "Data for Export",
      slug: "/data-for-export",
      icon: <FaDatabase />,
      description:
        "Exportable tables comprising data in market overview, membership stats, and sales opportunity at msa level, with parish list by county",
      dashboards: { ...dashboards.marketOpportunityGenerator.dataForExport },
    },
    {
      title: "User Guide",
      slug: "/user-guide",
      icon: <FaBook />,
      description:
        "Instruction for using and interpreting the dashboards on the portal",
      pdf: "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/catholic_united/MOG_User_Guide-Catholic_United_Financial_2023deployment.pdf",
    },
  ],
};

// Intelligent Goal Setter
export const intelligentGoalSetter = {
  title: "Intelligent Goal Setter",
  slug: "/intelligent-goal-setter",
  // slug: "#",
  icon: <LuGoal />,
  tabs: [
    {
      title: "Sales Goal Inputs",
      icon: "",
    },
    {
      title: "Sales Goal Outputs",
      icon: "",
    },
    // {
    //   title: "C for S Comparison",
    //   icon: "",
    // },
    // {
    //   title: "2023 Sales Comparison",
    //   icon: "",
    // },
    {
      title: "Team & Region Factbase",
      icon: "",
    },
    {
      title: "Instructions & Assumptions",
      icon: "",
    },
    // {
    //   title: "Assumptions",
    //   icon: "",
    // },
  ],
};

//
export const territoryDesign = {
  title: "Territory Design",
  // slug: "/territory-design",
  slug: "#",
  icon: <FaMap />,
  dashboards: { ...dashboards.territoryDesign },
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const home = [
  marketOpportunityGenerator,
  intelligentGoalSetter,
  // territoryDesign,
];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...home,
];

// export const home = [marketOpportunityGenerator];
