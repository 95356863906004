import React, { useEffect, useState } from "react";
import { CurrencyFormatter, NumberFormatter } from "../../utils/Utils";
import CforSComparison from "./CforSComparison";
import SalesComparison2023 from "./SalesComparison2023";
import { CSVDownload, CSVLink } from "react-csv";

const SalesGoalsOutput = ({ outputData }) => {
  const [tableName, setTableName] = useState([
    "2024 Proposed Sales Goal",
    "Proposed Life Sales Goal Split: non-SPYT and SPYT",
  ]);
  const [products, setProducts] = useState(["Life", "Annuity", "Med Supp"]);
  const [products2, setProducts2] = useState(["Life non-SPYT", "Life SPYT"]);
  const [subTableTile, setSubTableTile] = useState(["Premium", "Policies"]);
  const [region, setRegion] = useState([
    "Central",
    "Lakeland",
    "Maple Trail",
    "Northwoods",
    "Southwest",
    "Countryside",
    "Prairie View",
    "River Ridge",
    "Total",
  ]);

  const [active, setActive] = useState("Output");
  const [subActive, setSubActive] = useState("");

  // Export to CSV states
  const [proposedSalesGoalHeader, setProposedSalesGoalHeader] = useState(null);
  const [proposedSalesGoalData, setProposedSalesGoalData] = useState(null);
  const [proposedLifeSalesHeader, setProposedLifeSalesHeader] = useState(null);
  const [proposedLifeSalesData, setProposedLifeSalesData] = useState(null);

  // Assign to states for export
  useEffect(() => {
    // proposedSalesGoals
    const proposedSalesGoals = Object.entries(
      outputData.proposed_sales_goal_2024
    );
    exportDataCreation(
      proposedSalesGoals,
      setProposedSalesGoalHeader,
      setProposedSalesGoalData
    );

    // proposedSalesLife
    const proposedSalesLife = Object.entries(
      outputData.proposed_sales_goal_life_split
    );
    exportDataCreation(
      proposedSalesLife,
      setProposedLifeSalesHeader,
      setProposedLifeSalesData
    );
  }, []);

  // function to create data
  const exportDataCreation = (object, setCsvHeader, setCsvData) => {
    let csvHeader = [];
    let csvData = [];

    object.forEach((item) => {
      const temp = { label: `${item[0]}`, key: `${item[0]}` };
      csvHeader.push(temp);
    });

    setCsvHeader([{ label: "Region/Team", key: "Region/Team" }, ...csvHeader]);

    region.forEach((reg, id) => {
      let temp = { "Region/Team": `${reg}` };
      for (let index = 0; index < object.length; index++) {
        const element = object[index];
        temp = {
          ...temp,
          [element[0]]: element[0].includes("Premium")
            ? CurrencyFormatter(element[1][reg])
            : NumberFormatter(element[1][reg]),
        };
      }
      csvData.push(temp);
    });

    setCsvData(csvData);
  };

  return (
    <div>
      <div className="flex gap-5 w-full justify-between text-sm text-gray-700 relative">
        {/* Navigations */}
        <div className="w-3/12 xl:w-1/6 p-5 pl-0 border-r font-medium text-[11px] xl:text-xs relative">
          <div className="flex flex-col gap-5 sticky top-24">
            <div
              className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
                active === "Output" ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive("Output")}
            >
              Proposed Goals
            </div>

            <div
              className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
                active === "cfors" ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => {
                setActive("cfors");
                setSubActive("Life");
              }}
            >
              Contract for Success Comparision
            </div>
            {/* Sub Tab for C for S Comparision */}
            {active === "cfors" && (
              <>
                <div
                  className={`p-3 mt-[-15px] ml-4  rounded-md text-white hover:shadow-md cursor-pointer ${
                    active === "cfors" && subActive === "Life"
                      ? "bg-cyan-800"
                      : "bg-tertiary"
                  }`}
                  onClick={() => {
                    setSubActive("Life");
                  }}
                >
                  Life
                </div>
                <div
                  className={`p-3 mt-[-15px] ml-4  rounded-md text-white hover:shadow-md cursor-pointer ${
                    active === "cfors" && subActive === "Annuity"
                      ? "bg-cyan-800"
                      : "bg-tertiary"
                  }`}
                  onClick={() => {
                    setSubActive("Annuity");
                  }}
                >
                  Annuity
                </div>
                <div
                  className={`p-3 mt-[-15px] ml-4  rounded-md text-white hover:shadow-md cursor-pointer ${
                    active === "cfors" && subActive === "Med Supp"
                      ? "bg-cyan-800"
                      : "bg-tertiary"
                  }`}
                  onClick={() => {
                    setSubActive("Med Supp");
                  }}
                >
                  Med Supp
                </div>
              </>
            )}

            <div
              className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
                active === "salesComparision2023"
                  ? "bg-cyan-800"
                  : "bg-tertiary"
              }`}
              onClick={() => {
                setActive("salesComparision2023");
                setSubActive("Life");
              }}
            >
              2023 Sales Comparision
            </div>
            {/* Sub Tab for 2023 Sales Goal Comparision */}
            {active === "salesComparision2023" && (
              <>
                <div
                  className={`p-3 mt-[-15px] ml-4  rounded-md text-white hover:shadow-md cursor-pointer ${
                    active === "salesComparision2023" && subActive === "Life"
                      ? "bg-cyan-800"
                      : "bg-tertiary"
                  }`}
                  onClick={() => {
                    setSubActive("Life");
                  }}
                >
                  Life
                </div>
                <div
                  className={`p-3 mt-[-15px] ml-4  rounded-md text-white hover:shadow-md cursor-pointer ${
                    active === "salesComparision2023" && subActive === "Annuity"
                      ? "bg-cyan-800"
                      : "bg-tertiary"
                  }`}
                  onClick={() => {
                    setSubActive("Annuity");
                  }}
                >
                  Annuity
                </div>
                <div
                  className={`p-3 mt-[-15px] ml-4  rounded-md text-white hover:shadow-md cursor-pointer ${
                    active === "salesComparision2023" &&
                    subActive === "Med Supp"
                      ? "bg-cyan-800"
                      : "bg-tertiary"
                  }`}
                  onClick={() => {
                    setSubActive("Med Supp");
                  }}
                >
                  Med Supp
                </div>
              </>
            )}
          </div>
        </div>

        {/* Outputs */}
        <div className="w-9/12 xl:w-5/6 h-full">
          {/* Salesgoals Output */}
          {active === "Output" && (
            <div className="border rounded-md shadow-md p-5 pt-0">
              {tableName.map((name, index) => {
                return (
                  <div key={name.replace(/\s/g, "")}>
                    <div className="flex justify-between items-center">
                      <h3 className="font-bold my-5">{name}</h3>
                      {/* Export to csv */}
                      {name === "2024 Proposed Sales Goal" ? (
                        <>
                          {proposedSalesGoalData && proposedSalesGoalHeader && (
                            <CSVLink
                              data={proposedSalesGoalData}
                              headers={proposedSalesGoalHeader}
                              filename={name}
                              className="px-4 py-2 my-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                            >
                              Export to .csv
                            </CSVLink>
                          )}
                        </>
                      ) : (
                        <>
                          {proposedLifeSalesData && proposedLifeSalesHeader && (
                            <CSVLink
                              data={proposedLifeSalesData}
                              headers={proposedLifeSalesHeader}
                              filename={name}
                              className="px-4 py-2 my-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                            >
                              Export to .csv
                            </CSVLink>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className={`border shadow rounded-lg overflow-auto mb-5 ${
                        index === 0 ? "w-full" : "w-3/5"
                      }`}
                    >
                      <table className="w-full">
                        <thead className="">
                          <tr className="bg-cyan-800 text-white">
                            <th
                              colSpan={1}
                              rowSpan={2}
                              className="text-left p-2"
                            >
                              Region / Team
                            </th>
                            {index === 0
                              ? products.map((product, idx) => (
                                  <th
                                    className="p-2 border-l"
                                    colSpan={2}
                                    key={idx}
                                  >
                                    {product}
                                  </th>
                                ))
                              : products2.map((product2, idx) => (
                                  <th
                                    className="p-2 border-l"
                                    colSpan={2}
                                    key={idx}
                                  >
                                    {product2}
                                  </th>
                                ))}
                            {index === 0 && (
                              <th colSpan={3} className="p-2 border-l">
                                Total
                              </th>
                            )}
                          </tr>
                          <tr className="bg-cyan-800 text-white">
                            {products.map((title, idx) => (
                              <>
                                {subTableTile.map((item, idx2) => {
                                  if (index === 1 && idx === 2) return;
                                  return (
                                    <th
                                      className="p-2 border-l border-t"
                                      key={idx + item}
                                    >
                                      {item}
                                    </th>
                                  );
                                })}
                              </>
                            ))}

                            {index === 0 && (
                              <>
                                <th className="p-2 border-l border-t">
                                  New Members
                                </th>
                                <th className="p-2 border-l border-t">
                                  Policies
                                </th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {region.map((team, idx) => (
                            <tr
                              key={idx}
                              className={`hover:bg-gray-200 ${
                                idx % 2 === 0 ? "" : "bg-gray-100"
                              }`}
                            >
                              <td
                                className={`p-2 w-32 text-left ${
                                  team === "Central" ||
                                  team === "Southwest" ||
                                  team === "Total"
                                    ? "font-bold"
                                    : "pl-4"
                                }`}
                              >
                                {team}
                              </td>

                              {index === 0 ? (
                                <>
                                  {products.map((product, idx) => (
                                    <>
                                      <td
                                        className={`p-2 w-32 ${
                                          (team === "Central" ||
                                            team === "Southwest" ||
                                            team === "Total") &&
                                          "font-bold"
                                        }`}
                                      >
                                        {CurrencyFormatter(
                                          outputData[
                                            "proposed_sales_goal_2024"
                                          ][`${product} Premium`][team]
                                        )}
                                      </td>
                                      <td
                                        className={`p-2 w-32 ${
                                          (team === "Central" ||
                                            team === "Southwest" ||
                                            team === "Total") &&
                                          "font-bold"
                                        }`}
                                      >
                                        {NumberFormatter(
                                          outputData[
                                            "proposed_sales_goal_2024"
                                          ][`${product} Policies`][team]
                                        )}
                                      </td>
                                    </>
                                  ))}
                                  <td
                                    className={` p-2 w-32 ${
                                      (team === "Central" ||
                                        team === "Southwest" ||
                                        team === "Total") &&
                                      "font-bold"
                                    }`}
                                  >
                                    {NumberFormatter(
                                      outputData["proposed_sales_goal_2024"][
                                        "Total New Members"
                                      ][team]
                                    )}
                                  </td>
                                  <td
                                    className={`p-2 w-32 ${
                                      (team === "Central" ||
                                        team === "Southwest" ||
                                        team === "Total") &&
                                      "font-bold"
                                    }`}
                                  >
                                    {NumberFormatter(
                                      outputData["proposed_sales_goal_2024"][
                                        "Total Policies"
                                      ][team]
                                    )}
                                  </td>
                                </>
                              ) : (
                                <>
                                  {products2.map((product2, idx) => (
                                    <>
                                      <td
                                        className={`p-2 w-32 ${
                                          (team === "Central" ||
                                            team === "Southwest" ||
                                            team === "Total") &&
                                          "font-bold"
                                        }`}
                                      >
                                        {CurrencyFormatter(
                                          outputData[
                                            "proposed_sales_goal_life_split"
                                          ][`${product2} Premium`][team]
                                        )}
                                      </td>
                                      <td
                                        className={`p-2 w-32 ${
                                          (team === "Central" ||
                                            team === "Southwest" ||
                                            team === "Total") &&
                                          "font-bold"
                                        }`}
                                      >
                                        {NumberFormatter(
                                          outputData[
                                            "proposed_sales_goal_life_split"
                                          ][`${product2} Policies`][team]
                                        )}
                                      </td>
                                    </>
                                  ))}
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* C for S Comparison */}
          {active === "cfors" && (
            <CforSComparison outputData={outputData} active={subActive} />
          )}

          {/* Sales comparison 2023 */}
          {active === "salesComparision2023" && (
            <SalesComparison2023 outputData={outputData} active={subActive} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesGoalsOutput;
