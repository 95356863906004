export const ISGS_input_data = {
  // ################################################## Comparison Perpose Data ##################################################
  // # Company Sales Goal (as reported to board)
  goal_year: 2024,
  new_members: 809,
  annualized_sales_prem_life: 753774,
  annualized_sales_prem_annutiy: 30000000,
  annualized_sales_prem_med: 303503,
  annualized_sales_prem_non_spyt: 732294,
  annualized_sales_prem_spyt: 21480,
  sold_apps_life: 926,
  sold_apps_annuity: 1066,
  sold_apps_med: 148,
  sold_apps_non_spyt: 678,
  sold_apps_spyt: 248,
  // # Weighting of Sales Year in Calculating weighted Average Sales
  historical_sales: [0.2, 0.2, 0.6],
  market_size: [0.2, 0.2, 0.6],
  // # Sales Goal Allocation Weighting by Factbase Metric
  premium_weighting: [0.3, 0, 0.2, 0, 0.1, 0, 0.1, 0.1, 0.2, 0],
  sold_policy_weighting: [0, 0.3, 0, 0.2, 0, 0.1, 0.1, 0.1, 0.2, 0],
  // # Sales written by Member Advisors
  sales_by_member_advisors: [0.9, 0.1],
  // # 2024 Member Advisor Staffing Levels by Tenure
  // # (suffix "_nh" added for new hires)
  lakeland_nh: 2,
  maple_trail_nh: 1,
  northwoods_nh: 1,
  countryside_nh: 2,
  praire_view_nh: 0,
  river_ridge_nh: 2,
  // # (suffix "_2less" added for <2 Yr Tenure)
  lakeland_2less: 1,
  maple_trail_2less: 2,
  northwoods_2less: 1,
  countryside_2less: 2,
  praire_view_2less: 0,
  river_ridge_2less: 0,
  // # (suffix "_2_5" added for 2-5 Yr Tenure)
  lakeland_2_5: 0,
  maple_trail_2_5: 0,
  northwoods_2_5: 1,
  countryside_2_5: 2,
  praire_view_2_5: 1,
  river_ridge_2_5: 0,
  // # (suffix "_5more" added for 5+ Yr Tenure)
  lakeland_5more: 1,
  maple_trail_5more: 1,
  northwoods_5more: 2,
  countryside_5more: 0,
  praire_view_5more: 5,
  river_ridge_5more: 3,
  // # Member Advisor Productivity Target
  policies_per_member_nh: 25,
  policies_per_member_2less: 40,
  policies_per_member_2_5: 60,
  policies_per_member_5more: 80,
  // # Productivity Adjusted Goal Factors Lookup Table
  policies_per_member_advisors_nh: 25,
  policies_per_member_advisors_nh: 25,
  policies_per_member_advisors_nh: 25,
  // ################################################## Comparison Perpose Data ##################################################
  // # Contract for Success Objectives (for Comparison Purposes Only)
  life_premium_comparison: [60870, 72100, 134860, 132045, 208895, 132800],
  life_policies_comparison: [100, 91, 180, 165, 230, 137],
  annuity_premium_comparison: [
    2000000, 3000000, 5200000, 4800000, 6000000, 3400000,
  ],
  annuity_policies_comparison: [60, 70, 135, 81, 216, 103],
  med_premium_comparison: [41400, 16800, 105750, 44000, 97500, 58000],
  med_policies_comparison: [15, 7, 48, 21, 47, 31],
};

export const ISGS_companySalesGoals = {
  goal_year: 2024,
  new_members: 809,
  // # Company Sales Goal (as reported to board)
  annualized_sales_prem_annutiy: 30000000,
  annualized_sales_prem_med: 303503,
  annualized_sales_prem_non_spyt: 732294,
  annualized_sales_prem_spyt: 21480,
  sold_apps_annuity: 1066,
  sold_apps_med: 148,
  sold_apps_non_spyt: 678,
  sold_apps_spyt: 248,
};

export const ISGS_salesYearWeighting = {
  // # Weighting of Sales Year in Calculating weighted Average Sales
  historical_sales_1: 20,
  historical_sales_2: 20,
  historical_sales_3: 60,
  market_size_1: 20,
  market_size_2: 20,
  market_size_3: 60,
};

export const ISGS_allocatedSalesGoalWeighting = {
  // # Sales Goal Allocation Weighting by Factbase Metric
  // # (suffix "_pw" added for Premium Weighting)
  historical_sales_pw: 30,
  historical_sold_pw: 0,
  market_size_sales_pw: 20,
  market_size_sold_pw: 0,
  inforce_premium_pw: 10,
  inforce_members_pw: 0,
  catholic_population_pw: 10,
  catholic_parishes_pw: 10,
  historical_grants_pw: 20,
  member_advisors_pw: 0,
  // # (suffix "_spw" added for Sold Premium Weighting)
  historical_sales_spw: 0,
  historical_sold_spw: 30,
  market_size_sales_spw: 0,
  market_size_sold_spw: 20,
  inforce_premium_spw: 0,
  inforce_members_spw: 10,
  catholic_population_spw: 10,
  catholic_parishes_spw: 10,
  historical_grants_spw: 20,
  member_advisors_spw: 0,
};

export const ISGS_shareMemberAdvisors = {
  // # Sales written by Member Advisors
  sales_from_member_advisors: 90,
  total_sales: 100,
};

export const ISGS_memberAdvisorsStaffing = {
  // # 2024 Member Advisor Staffing Levels by Tenure
  // # (suffix "_nh" added for new hires)
  lakeland_nh: 2,
  maple_trail_nh: 1,
  northwoods_nh: 1,
  countryside_nh: 2,
  praire_view_nh: 0,
  river_ridge_nh: 2,
  // # (suffix "_2less" added for <2 Yr Tenure)
  lakeland_2less: 1,
  maple_trail_2less: 2,
  northwoods_2less: 1,
  countryside_2less: 2,
  praire_view_2less: 0,
  river_ridge_2less: 0,
  // # (suffix "_2_5" added for 2-5 Yr Tenure)
  lakeland_2_5: 0,
  maple_trail_2_5: 0,
  northwoods_2_5: 1,
  countryside_2_5: 2,
  praire_view_2_5: 1,
  river_ridge_2_5: 0,
  // # (suffix "_5more" added for 5+ Yr Tenure)
  lakeland_5more: 1,
  maple_trail_5more: 1,
  northwoods_5more: 2,
  countryside_5more: 0,
  praire_view_5more: 5,
  river_ridge_5more: 3,
  // # Member Advisor Productivity Target
  policies_per_member_nh: 25,
  policies_per_member_2less: 40,
  policies_per_member_2_5: 60,
  policies_per_member_5more: 80,
  // # Productivity Adjusted Goal Factors Lookup Table
  policies_per_member_advisors_nh: 25,
  policies_per_member_advisors_nh: 25,
  policies_per_member_advisors_nh: 25,
};

export const ISGS_contractComparison = {
  // # CUF 2024 Bottom Up Sales Goals
  lakeland_life_prem: 60870,
  lakeland_life_pols: 100,
  maple_trail_life_prem: 72100,
  maple_trail_life_pols: 91,
  northwoods_life_prem: 134860,
  northwoods_life_pols: 180,
  countryside_life_prem: 132045,
  countryside_life_pols: 165,
  prairie_view_life_prem: 208895,
  prairie_view_life_pols: 230,
  river_ridge_life_prem: 132800,
  river_ridge_life_pols: 137,

  lakeland_annuity_prem: 2000000,
  lakeland_annuity_pols: 60,
  maple_trail_annuity_prem: 3000000,
  maple_trail_annuity_pols: 70,
  northwoods_annuity_prem: 5200000,
  northwoods_annuity_pols: 135,
  countryside_annuity_prem: 4800000,
  countryside_annuity_pols: 81,
  prairie_view_annuity_prem: 6000000,
  prairie_view_annuity_pols: 216,
  river_ridge_annuity_prem: 3400000,
  river_ridge_annuity_pols: 103,

  lakeland_med_prem: 41400,
  lakeland_med_pols: 15,
  maple_trail_med_prem: 16800,
  maple_trail_med_pols: 7,
  northwoods_med_prem: 105750,
  northwoods_med_pols: 48,
  countryside_med_prem: 44000,
  countryside_med_pols: 21,
  prairie_view_med_prem: 97500,
  prairie_view_med_pols: 47,
  river_ridge_med_prem: 58000,
  river_ridge_med_pols: 31,
};
