import React, { useContext, useEffect } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, territoryDesign } from "../../data/data";
import PowerBI from "../../components/powerBIAuth/PowerBI";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const TerritoryDesign = () => {
  const { setPageTitle, userActivityTracker } = useContext(UserContext);

  useEffect(() => {
    setPageTitle(territoryDesign.title);
    userActivityTracker("Page changed", territoryDesign.title);
  }, []);

  return (
    <section className="p-5 pt-0 w-full md:min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(territoryDesign)} />
      </div>
      <div className="w-full mb-10 py-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out">
        <PowerBI reportId={territoryDesign.dashboards.reportId} note={null} />
      </div>
      {/* Powered By Spinnaker */}
      {/* <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div> */}
    </section>
  );
};

export default TerritoryDesign;
