// ***************************************************************  All Power BI Dashboards  ***************************************************************

export const dashboards = {
  // Market Opportunity Generator
  marketOpportunityGenerator: {
    marketOverview: {
      deployment2023: {
        reportId: "9ad93c5f-828d-41b5-ae13-ae9b6bd54334",
      },
      refresh2024: {
        reportId: "4b307641-7d6e-4529-9a88-26675196056a",
      },
    },
    cufMembershipStats: {
      deployment2023: {
        reportId: "71163692-2433-497b-85dc-c875ff510e7c",
      },
      refresh2024: {
        reportId: "e16960e2-8f4d-4f5f-a6c1-aed822f4a6fa",
      },
    },
    cufSalesRegions: {
      deployment2023: {
        reportId: "72a982cc-cb3c-4f4e-9559-0cc07d77d98d",
      },
      refresh2024: {
        reportId: "e1660328-dcf9-4286-a384-8e790f11d079",
      },
    },
    cufSalesOpportunity: {
      deployment2023: {
        reportId: "43d73eff-67a8-44d0-b234-61fa1e0d94be",
      },
      refresh2024: {
        reportId: "76275519-586f-4741-aa54-a67e337760a4",
      },
    },
    dataForExport: {
      deployment2023: {
        reportId: "6ca0c426-3217-48ee-9fa7-25d789471073",
      },
      refresh2024: {
        reportId: "f77f9fdc-3b14-4773-bbdd-54b3f2df6e52",
      },
    },
  },
  territoryDesign: {
    reportId: "a028cb40-156f-428c-a8a0-31b5c0cefd38",
    // reportId: "02e83cc0-73c3-4b4d-b23f-e7fc4eff08a8",
  },
};
