import React from "react";

const Instructions = () => {
  return (
    <div className="w-full">
      <h2 className="p-4 bg-cyan-800 font-bold text-xl text-white rounded-md">
        Intelligent Goal Setter: Instructions
      </h2>
      <div className="my-4">
        <h2 className="font-semibold text-base">1) Company Sales Goals</h2>
        <ul className="list-disc pl-8 leading-6">
          {/* <li>Enter the Goal Year to be estimated</li> */}
          <li>Specify the count of new unique members to be sold</li>
          <li>
            Enter the annualized sales premium goal for each product and
            sub-product grouping
          </li>
          <li>
            Enter the number of new sold policies for each product and
            sub-product grouping
          </li>
        </ul>
      </div>

      <div className="my-4">
        <h2 className="font-semibold text-base">
          2) Allocated Sales Goal Weightings (Sales Years & Factbase Metric)
        </h2>
        <ul className="list-disc pl-8 leading-6">
          <li>
            For each parameter, specify the contribution of each year of
            historical sales data to calculate weighted average sales
          </li>
          <li>
            Ensure that the total weighting for each row (historical sales and
            market size) is exactly 100%
          </li>
          <li>
            Specify the contribution for each factbase metric to calculate the
            weighted allocation of sales goals by premium and policies
          </li>
          <li>
            Ensure that the total weighting for each column (premium and sold
            policies) is exactly 100%
          </li>
        </ul>
      </div>

      <div className="my-4">
        <h2 className="font-semibold text-base">
          3) Member Advisor Metrics (Share of sales, productivity level, and
          staffing)
        </h2>
        <ul className="list-disc pl-8 leading-6">
          <li>
            <p>
              Specify the percent of sales sourced directly from Member Advisors
              vs. Other (e.g. Catholic United distribution support and
              leadership)
            </p>
            <p>NOTE: Sales from Other is automatically calculated</p>
          </li>
        </ul>
        <ul className="list-disc pl-8 leading-6">
          <li>
            For each Member Advisor cohort, specify the expected annual sales
            production targets in issued policies across all products
          </li>
          <li>
            For each region and team, specify the number of new hires planned
            for the Goal Year
          </li>
          <li>
            <p>
              For each region and team, specify the current Member Advisor
              headcount based on tenure as a licensed sales rep with Catholic
              United
            </p>
            <p>
              NOTE: Tenure should be calculated as the difference in years
              between Member Advisor start date and January 1st of the Goal Year{" "}
            </p>
          </li>
        </ul>
      </div>

      <div className="my-4">
        <h2 className="font-semibold text-base">
          4) Contract for Success Objectives
        </h2>
        <ul className="list-disc pl-8 leading-6">
          <li>
            Enter the expected sales production estimated by Catholic United
            Team Leaders for the Goal Year
          </li>
          <li>NOTE: These factors excludes production from New Hires</li>
        </ul>
      </div>
    </div>
  );
};

export default Instructions;
