import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import StateTabs from "../../components/account/StateTabs";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, intelligentGoalSetter } from "../../data/data";
import SalesGoalsInputs from "../../components/modules/intelligentSalesGoalSetter/SalesGoalsInputs";
import SalesGoalsOutput from "../../components/modules/intelligentSalesGoalSetter/SalesGoalsOutput";
import CforSComparison from "../../components/modules/intelligentSalesGoalSetter/CforSComparison";
import SalesComparison2023 from "../../components/modules/intelligentSalesGoalSetter/SalesComparison2023";

import API from "../../api/api";
import { ISGS_input_data } from "../../data/modules/intelligentSalesGoalSetter";
import SalesGoalFactbase from "../../components/modules/intelligentSalesGoalSetter/SalesGoalFactbase";
import InstructionsAssumptions from "../../components/modules/intelligentSalesGoalSetter/InstructionsAssumptions";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const IntelligentSalesGoalSetter = () => {
  const { setPageTitle, userActivityTracker } = useContext(UserContext);
  const [active, setActive] = useState("Sales Goal Inputs");
  const [outputData, setOutputData] = useState(null);
  const [factbaseData, setFactbaseData] = useState(null);

  useEffect(() => {
    setPageTitle(intelligentGoalSetter.title);
    userActivityTracker("Page changed", intelligentGoalSetter.title);
  }, []);

  useEffect(() => {
    API.post("/cuf/sales-goal-engine", ISGS_input_data)
      .then(({ data }) => {
        console.log(data);
        setOutputData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    API.post("/cuf/sales-goal-factbase", ISGS_input_data)
      .then(({ data }) => {
        // console.log(data);
        setFactbaseData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className="p-5 pt-0 w-full md:min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(intelligentGoalSetter)} />
      </div>
      <div className="my-5">
        <StateTabs
          data={intelligentGoalSetter.tabs}
          state={active}
          setState={setActive}
        />
      </div>
      {/* Content */}
      <div className={active === "Sales Goal Inputs" ? "" : "hidden"}>
        <SalesGoalsInputs setPage={setActive} setOutputData={setOutputData} />
      </div>
      {active === "Team & Region Factbase" && factbaseData && (
        <SalesGoalFactbase factbaseData={factbaseData} />
      )}
      <div className={active === "Sales Goal Outputs" ? "" : "hidden"}>
        {outputData && <SalesGoalsOutput outputData={outputData} />}
      </div>
      {active === "Instructions & Assumptions" && <InstructionsAssumptions />}
    </section>
  );
};

export default IntelligentSalesGoalSetter;
