import React, { useEffect, useState } from "react";
import {
  ISGS_allocatedSalesGoalWeighting,
  ISGS_companySalesGoals,
  ISGS_contractComparison,
  ISGS_input_data,
  ISGS_memberAdvisorsStaffing,
  ISGS_salesYearWeighting,
  ISGS_shareMemberAdvisors,
} from "../../../data/modules/intelligentSalesGoalSetter";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter,
  PercentFormatterForInputs,
  convertToPercent,
  handleBackspaceForPercentage,
} from "../../utils/Utils";
import { IoMdArrowRoundBack } from "react-icons/io";
import API from "../../../api/api";
import { LuLoader2 } from "react-icons/lu";

const SalesGoalsInputs = ({ setPage, setOutputData }) => {
  const [CSG_Inputs, setCSG_Inputs] = useState(ISGS_companySalesGoals);
  const [SYW_Inputs, setSYW_Inputs] = useState(ISGS_salesYearWeighting);
  const [ASGW_Inputs, setASGW_Inputs] = useState(
    ISGS_allocatedSalesGoalWeighting
  );
  const [SMA_Inputs, setSMA_Inputs] = useState(ISGS_shareMemberAdvisors);
  const [MAS_Inputs, setMAS_Inputs] = useState(ISGS_memberAdvisorsStaffing);
  const [CC_Inputs, setCC_Inputs] = useState(ISGS_contractComparison);
  const [active, setActive] = useState(1);
  const [showOutput, setShowOutput] = useState(true);
  const [totalHistoricalSales, setTotalHistoricalSales] = useState(null);
  const [totalMarketSize, setTotalMarketSize] = useState(null);
  const [totalPW, setTotalPW] = useState(null);
  const [totalSPW, setTotalSPW] = useState(null);
  const [load, setLoad] = useState(false);

  // All inputs with currency
  const handleCurrencyChange = (e) => {
    setShowOutput(false);
    const removeDollar = e?.target?.value?.split("$");
    const removeComma = removeDollar[1]
      ? removeDollar[1]?.split(",")?.join("")
      : 0;
    const value = parseInt(removeComma);
    // console.log(e.target.value);
    if (CSG_Inputs.hasOwnProperty(e.target.name)) {
      setCSG_Inputs(() => ({
        ...CSG_Inputs,
        [e.target.name]: value,
      }));
    } else if (CC_Inputs.hasOwnProperty(e.target.name)) {
      setCC_Inputs(() => ({
        ...CC_Inputs,
        [e.target.name]: value,
      }));
    }
  };

  // All inputs with just whole numbers
  const handleNumberChange = (e) => {
    setShowOutput(false);
    const removeComma = e.target.value?.split(",")?.join("");
    const value = parseInt(removeComma ? removeComma : 0);
    if (CSG_Inputs.hasOwnProperty(e.target.name)) {
      setCSG_Inputs(() => ({
        ...CSG_Inputs,
        [e.target.name]: value,
      }));
    } else if (MAS_Inputs.hasOwnProperty(e.target.name)) {
      setMAS_Inputs(() => ({
        ...MAS_Inputs,
        [e.target.name]: value,
      }));
    } else if (CC_Inputs.hasOwnProperty(e.target.name)) {
      setCC_Inputs(() => ({
        ...CC_Inputs,
        [e.target.name]: value,
      }));
    }
  };

  // All inputs with percentages
  const handlePercentageChange = (e) => {
    setShowOutput(false);
    const removePercent = e?.target?.value?.split("%")?.join("");
    const value = parseFloat(removePercent ? removePercent : 0);
    changeStateForPercent(e, value)
  };

  // preset function to change all states with percentages
  const changeStateForPercent = (e, value) => {
    if (CSG_Inputs.hasOwnProperty(e.target.name)) {
      setCSG_Inputs(() => ({
        ...CSG_Inputs,
        [e.target.name]: value,
      }));
    } else if (SYW_Inputs.hasOwnProperty(e.target.name)) {
      setSYW_Inputs(() => ({
        ...SYW_Inputs,
        [e.target.name]: value,
      }));
    } else if (ASGW_Inputs.hasOwnProperty(e.target.name)) {
      setASGW_Inputs(() => ({
        ...ASGW_Inputs,
        [e.target.name]: value,
      }));
    } else if (SMA_Inputs.hasOwnProperty(e.target.name)) {
      setSMA_Inputs(() => ({
        ...SMA_Inputs,
        [e.target.name]: value,
      }));
    } else if (CC_Inputs.hasOwnProperty(e.target.name)) {
      setCC_Inputs(() => ({
        ...CC_Inputs,
        [e.target.name]: value,
      }));
    }
  }

  // Trigger submit form to get sales goal
  const submitForm = async (e) => {
    e.preventDefault();
    setLoad(true);
    setShowOutput(false);

    // # Company Sales Goal (as reported to board)
    const company_sales_goal = {
      ...CSG_Inputs,
      annualized_sales_prem_life:
        CSG_Inputs.annualized_sales_prem_non_spyt +
        CSG_Inputs.annualized_sales_prem_spyt,
      sold_apps_life: CSG_Inputs.sold_apps_non_spyt + CSG_Inputs.sold_apps_spyt,
    };

    // # Weighting of Sales Year in Calculating weighted Average Sales
    const weighting_sales = {
      historical_sales: [
        convertToPercent(SYW_Inputs.historical_sales_1),
        convertToPercent(SYW_Inputs.historical_sales_2),
        convertToPercent(SYW_Inputs.historical_sales_3),
      ],
      market_size: [
        convertToPercent(SYW_Inputs.market_size_1),
        convertToPercent(SYW_Inputs.market_size_2),
        convertToPercent(SYW_Inputs.market_size_3),
      ],
    };

    // # Sales Goal Allocation Weighting by Factbase Metric
    const sales_goal_allocation = {
      premium_weighting: [
        convertToPercent(ASGW_Inputs.historical_sales_pw),
        convertToPercent(ASGW_Inputs.historical_sold_pw),
        convertToPercent(ASGW_Inputs.market_size_sales_pw),
        convertToPercent(ASGW_Inputs.market_size_sold_pw),
        convertToPercent(ASGW_Inputs.inforce_premium_pw),
        convertToPercent(ASGW_Inputs.inforce_members_pw),
        convertToPercent(ASGW_Inputs.catholic_population_pw),
        convertToPercent(ASGW_Inputs.catholic_parishes_pw),
        convertToPercent(ASGW_Inputs.historical_grants_pw),
        convertToPercent(ASGW_Inputs.member_advisors_pw),
      ],
      sold_policy_weighting: [
        convertToPercent(ASGW_Inputs.historical_sales_spw),
        convertToPercent(ASGW_Inputs.historical_sold_spw),
        convertToPercent(ASGW_Inputs.market_size_sales_spw),
        convertToPercent(ASGW_Inputs.market_size_sold_spw),
        convertToPercent(ASGW_Inputs.inforce_premium_spw),
        convertToPercent(ASGW_Inputs.inforce_members_spw),
        convertToPercent(ASGW_Inputs.catholic_population_spw),
        convertToPercent(ASGW_Inputs.catholic_parishes_spw),
        convertToPercent(ASGW_Inputs.historical_grants_spw),
        convertToPercent(ASGW_Inputs.member_advisors_spw),
      ],
    };

    // # Sales written by Member Advisors
    const sales_by_members_advisors = {
      sales_by_member_advisors: [
        convertToPercent(SMA_Inputs.sales_from_member_advisors),
        convertToPercent(
          SMA_Inputs.total_sales - SMA_Inputs.sales_from_member_advisors
        ),
      ],
    };

    // # Contract for Success Objectives (for Comparison Purposes Only)
    const contract_comparison = {
      life_premium_comparison: [
        CC_Inputs.lakeland_life_prem,
        CC_Inputs.maple_trail_life_prem,
        CC_Inputs.northwoods_life_prem,
        CC_Inputs.countryside_life_prem,
        CC_Inputs.prairie_view_life_prem,
        CC_Inputs.river_ridge_life_prem,
      ],
      life_policies_comparison: [
        CC_Inputs.lakeland_life_pols,
        CC_Inputs.maple_trail_life_pols,
        CC_Inputs.northwoods_life_pols,
        CC_Inputs.countryside_life_pols,
        CC_Inputs.prairie_view_life_pols,
        CC_Inputs.river_ridge_life_pols,
      ],
      annuity_premium_comparison: [
        CC_Inputs.lakeland_annuity_prem,
        CC_Inputs.maple_trail_annuity_prem,
        CC_Inputs.northwoods_annuity_prem,
        CC_Inputs.countryside_annuity_prem,
        CC_Inputs.prairie_view_annuity_prem,
        CC_Inputs.river_ridge_annuity_prem,
      ],
      annuity_policies_comparison: [
        CC_Inputs.lakeland_annuity_pols,
        CC_Inputs.maple_trail_annuity_pols,
        CC_Inputs.northwoods_annuity_pols,
        CC_Inputs.countryside_annuity_pols,
        CC_Inputs.prairie_view_annuity_pols,
        CC_Inputs.river_ridge_annuity_pols,
      ],
      med_premium_comparison: [
        CC_Inputs.lakeland_med_prem,
        CC_Inputs.maple_trail_med_prem,
        CC_Inputs.northwoods_med_prem,
        CC_Inputs.countryside_med_prem,
        CC_Inputs.prairie_view_med_prem,
        CC_Inputs.river_ridge_med_prem,
      ],
      med_policies_comparison: [
        CC_Inputs.lakeland_med_pols,
        CC_Inputs.maple_trail_med_pols,
        CC_Inputs.northwoods_med_pols,
        CC_Inputs.countryside_med_pols,
        CC_Inputs.prairie_view_med_pols,
        CC_Inputs.river_ridge_med_pols,
      ],
    };

    const finalData = {
      ...company_sales_goal,
      ...weighting_sales,
      ...sales_goal_allocation,
      ...sales_by_members_advisors,
      ...MAS_Inputs,
      ...contract_comparison,
    };

    API.post("/cuf/sales-goal-engine", finalData)
      .then(({ data }) => {
        console.log(data);
        setOutputData(data);
        setLoad(false);
        setShowOutput(true);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        setShowOutput(true);
      });
  };

  // Total of percentages of ASGW_Inputs
  useEffect(() => {
    setTotalPW(
      ASGW_Inputs.historical_sales_pw +
        ASGW_Inputs.historical_sold_pw +
        ASGW_Inputs.market_size_sales_pw +
        ASGW_Inputs.market_size_sold_pw +
        ASGW_Inputs.inforce_premium_pw +
        ASGW_Inputs.inforce_members_pw +
        ASGW_Inputs.catholic_population_pw +
        ASGW_Inputs.catholic_parishes_pw +
        ASGW_Inputs.historical_grants_pw +
        ASGW_Inputs.member_advisors_pw
    );
    setTotalSPW(
      ASGW_Inputs.historical_sales_spw +
        ASGW_Inputs.historical_sold_spw +
        ASGW_Inputs.market_size_sales_spw +
        ASGW_Inputs.market_size_sold_spw +
        ASGW_Inputs.inforce_premium_spw +
        ASGW_Inputs.inforce_members_spw +
        ASGW_Inputs.catholic_population_spw +
        ASGW_Inputs.catholic_parishes_spw +
        ASGW_Inputs.historical_grants_spw +
        ASGW_Inputs.member_advisors_spw
    );
  }, [ASGW_Inputs]);

  // Total of percentages of CSG_Inputs
  useEffect(() => {
    setTotalHistoricalSales(
      SYW_Inputs.historical_sales_1 +
        SYW_Inputs.historical_sales_2 +
        SYW_Inputs.historical_sales_3
    );
    setTotalMarketSize(
      SYW_Inputs.market_size_1 +
        SYW_Inputs.market_size_2 +
        SYW_Inputs.market_size_3
    );
  }, [SYW_Inputs]);

  // Trigger a warning on page reload
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue =
        "Reloading the page will result the output to be reset to default data";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  // Trigger this function to replace edited values with default value in relevant places
  const triggerDefaultValue = (object) => {
    setLoad(true);
    setShowOutput(false);
    let data = null;
    let company_sales_goal = null;
    let weighting_sales = null;
    let sales_goal_allocation = null;
    let sales_by_members_advisors = null;
    let contract_comparison = null;

    if (object === "CSG_Inputs") {
      setCSG_Inputs(ISGS_companySalesGoals);
      data = ISGS_companySalesGoals;
      // # Company Sales Goal (as reported to board)
      company_sales_goal = {
        ...data,
        annualized_sales_prem_life:
          data.annualized_sales_prem_non_spyt + data.annualized_sales_prem_spyt,
        sold_apps_life: data.sold_apps_non_spyt + data.sold_apps_spyt,
      };
    } else {
      // # Company Sales Goal (as reported to board)
      company_sales_goal = {
        ...CSG_Inputs,
        annualized_sales_prem_life:
          CSG_Inputs.annualized_sales_prem_non_spyt +
          CSG_Inputs.annualized_sales_prem_spyt,
        sold_apps_life:
          CSG_Inputs.sold_apps_non_spyt + CSG_Inputs.sold_apps_spyt,
      };
    }

    if (object === "SYW_Inputs") {
      setSYW_Inputs(ISGS_salesYearWeighting);
      data = ISGS_salesYearWeighting;
      // # Weighting of Sales Year in Calculating weighted Average Sales
      weighting_sales = {
        historical_sales: [
          convertToPercent(data.historical_sales_1),
          convertToPercent(data.historical_sales_2),
          convertToPercent(data.historical_sales_3),
        ],
        market_size: [
          convertToPercent(data.market_size_1),
          convertToPercent(data.market_size_2),
          convertToPercent(data.market_size_3),
        ],
      };
      setASGW_Inputs(ISGS_allocatedSalesGoalWeighting);
      data = ISGS_allocatedSalesGoalWeighting;
      // # Sales Goal Allocation Weighting by Factbase Metric
      sales_goal_allocation = {
        premium_weighting: [
          convertToPercent(data.historical_sales_pw),
          convertToPercent(data.historical_sold_pw),
          convertToPercent(data.market_size_sales_pw),
          convertToPercent(data.market_size_sold_pw),
          convertToPercent(data.inforce_premium_pw),
          convertToPercent(data.inforce_members_pw),
          convertToPercent(data.catholic_population_pw),
          convertToPercent(data.catholic_parishes_pw),
          convertToPercent(data.historical_grants_pw),
          convertToPercent(data.member_advisors_pw),
        ],
        sold_policy_weighting: [
          convertToPercent(data.historical_sales_spw),
          convertToPercent(data.historical_sold_spw),
          convertToPercent(data.market_size_sales_spw),
          convertToPercent(data.market_size_sold_spw),
          convertToPercent(data.inforce_premium_spw),
          convertToPercent(data.inforce_members_spw),
          convertToPercent(data.catholic_population_spw),
          convertToPercent(data.catholic_parishes_spw),
          convertToPercent(data.historical_grants_spw),
          convertToPercent(data.member_advisors_spw),
        ],
      };
    } else {
      // # Weighting of Sales Year in Calculating weighted Average Sales
      weighting_sales = {
        historical_sales: [
          convertToPercent(SYW_Inputs.historical_sales_1),
          convertToPercent(SYW_Inputs.historical_sales_2),
          convertToPercent(SYW_Inputs.historical_sales_3),
        ],
        market_size: [
          convertToPercent(SYW_Inputs.market_size_1),
          convertToPercent(SYW_Inputs.market_size_2),
          convertToPercent(SYW_Inputs.market_size_3),
        ],
      };
    }

    if (object === "ASGW_Inputs") {
    } else {
      // # Sales Goal Allocation Weighting by Factbase Metric
      sales_goal_allocation = {
        premium_weighting: [
          convertToPercent(ASGW_Inputs.historical_sales_pw),
          convertToPercent(ASGW_Inputs.historical_sold_pw),
          convertToPercent(ASGW_Inputs.market_size_sales_pw),
          convertToPercent(ASGW_Inputs.market_size_sold_pw),
          convertToPercent(ASGW_Inputs.inforce_premium_pw),
          convertToPercent(ASGW_Inputs.inforce_members_pw),
          convertToPercent(ASGW_Inputs.catholic_population_pw),
          convertToPercent(ASGW_Inputs.catholic_parishes_pw),
          convertToPercent(ASGW_Inputs.historical_grants_pw),
          convertToPercent(ASGW_Inputs.member_advisors_pw),
        ],
        sold_policy_weighting: [
          convertToPercent(ASGW_Inputs.historical_sales_spw),
          convertToPercent(ASGW_Inputs.historical_sold_spw),
          convertToPercent(ASGW_Inputs.market_size_sales_spw),
          convertToPercent(ASGW_Inputs.market_size_sold_spw),
          convertToPercent(ASGW_Inputs.inforce_premium_spw),
          convertToPercent(ASGW_Inputs.inforce_members_spw),
          convertToPercent(ASGW_Inputs.catholic_population_spw),
          convertToPercent(ASGW_Inputs.catholic_parishes_spw),
          convertToPercent(ASGW_Inputs.historical_grants_spw),
          convertToPercent(ASGW_Inputs.member_advisors_spw),
        ],
      };
    }

    if (object === "SMA_Inputs") {
      setSMA_Inputs(ISGS_shareMemberAdvisors);
      data = ISGS_shareMemberAdvisors;
      // # Sales written by Member Advisors
      sales_by_members_advisors = {
        sales_by_member_advisors: [
          convertToPercent(data.sales_from_member_advisors),
          convertToPercent(data.total_sales - data.sales_from_member_advisors),
        ],
      };
      setMAS_Inputs(ISGS_memberAdvisorsStaffing);
      data = ISGS_memberAdvisorsStaffing;
    } else {
      // # Sales written by Member Advisors
      sales_by_members_advisors = {
        sales_by_member_advisors: [
          convertToPercent(SMA_Inputs.sales_from_member_advisors),
          convertToPercent(
            SMA_Inputs.total_sales - SMA_Inputs.sales_from_member_advisors
          ),
        ],
      };
    }

    if (object === "CC_Inputs") {
      setCC_Inputs(ISGS_contractComparison);
      data = ISGS_contractComparison;
      // # Contract for Success Objectives (for Comparison Purposes Only)
      contract_comparison = {
        life_premium_comparison: [
          data.lakeland_life_prem,
          data.maple_trail_life_prem,
          data.northwoods_life_prem,
          data.countryside_life_prem,
          data.prairie_view_life_prem,
          data.river_ridge_life_prem,
        ],
        life_policies_comparison: [
          data.lakeland_life_pols,
          data.maple_trail_life_pols,
          data.northwoods_life_pols,
          data.countryside_life_pols,
          data.prairie_view_life_pols,
          data.river_ridge_life_pols,
        ],
        annuity_premium_comparison: [
          data.lakeland_annuity_prem,
          data.maple_trail_annuity_prem,
          data.northwoods_annuity_prem,
          data.countryside_annuity_prem,
          data.prairie_view_annuity_prem,
          data.river_ridge_annuity_prem,
        ],
        annuity_policies_comparison: [
          data.lakeland_annuity_pols,
          data.maple_trail_annuity_pols,
          data.northwoods_annuity_pols,
          data.countryside_annuity_pols,
          data.prairie_view_annuity_pols,
          data.river_ridge_annuity_pols,
        ],
        med_premium_comparison: [
          data.lakeland_med_prem,
          data.maple_trail_med_prem,
          data.northwoods_med_prem,
          data.countryside_med_prem,
          data.prairie_view_med_prem,
          data.river_ridge_med_prem,
        ],
        med_policies_comparison: [
          data.lakeland_med_pols,
          data.maple_trail_med_pols,
          data.northwoods_med_pols,
          data.countryside_med_pols,
          data.prairie_view_med_pols,
          data.river_ridge_med_pols,
        ],
      };
    } else {
      // # Contract for Success Objectives (for Comparison Purposes Only)
      contract_comparison = {
        life_premium_comparison: [
          CC_Inputs.lakeland_life_prem,
          CC_Inputs.maple_trail_life_prem,
          CC_Inputs.northwoods_life_prem,
          CC_Inputs.countryside_life_prem,
          CC_Inputs.prairie_view_life_prem,
          CC_Inputs.river_ridge_life_prem,
        ],
        life_policies_comparison: [
          CC_Inputs.lakeland_life_pols,
          CC_Inputs.maple_trail_life_pols,
          CC_Inputs.northwoods_life_pols,
          CC_Inputs.countryside_life_pols,
          CC_Inputs.prairie_view_life_pols,
          CC_Inputs.river_ridge_life_pols,
        ],
        annuity_premium_comparison: [
          CC_Inputs.lakeland_annuity_prem,
          CC_Inputs.maple_trail_annuity_prem,
          CC_Inputs.northwoods_annuity_prem,
          CC_Inputs.countryside_annuity_prem,
          CC_Inputs.prairie_view_annuity_prem,
          CC_Inputs.river_ridge_annuity_prem,
        ],
        annuity_policies_comparison: [
          CC_Inputs.lakeland_annuity_pols,
          CC_Inputs.maple_trail_annuity_pols,
          CC_Inputs.northwoods_annuity_pols,
          CC_Inputs.countryside_annuity_pols,
          CC_Inputs.prairie_view_annuity_pols,
          CC_Inputs.river_ridge_annuity_pols,
        ],
        med_premium_comparison: [
          CC_Inputs.lakeland_med_prem,
          CC_Inputs.maple_trail_med_prem,
          CC_Inputs.northwoods_med_prem,
          CC_Inputs.countryside_med_prem,
          CC_Inputs.prairie_view_med_prem,
          CC_Inputs.river_ridge_med_prem,
        ],
        med_policies_comparison: [
          CC_Inputs.lakeland_med_pols,
          CC_Inputs.maple_trail_med_pols,
          CC_Inputs.northwoods_med_pols,
          CC_Inputs.countryside_med_pols,
          CC_Inputs.prairie_view_med_pols,
          CC_Inputs.river_ridge_med_pols,
        ],
      };
    }

    const finalData = {
      ...company_sales_goal,
      ...weighting_sales,
      ...sales_goal_allocation,
      ...sales_by_members_advisors,
      ...MAS_Inputs,
      ...contract_comparison,
    };

    API.post("/cuf/sales-goal-engine", finalData)
      .then(({ data }) => {
        console.log(data);
        setOutputData(data);
        setLoad(false);
        setShowOutput(true);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        setShowOutput(true);
      });
  };

  return (
    <div>
      <div className="flex gap-5 w-full h-full justify-between text-sm text-gray-700 relative">
        {/* Navigation between forms */}
        <div className="w-3/12 xl:w-1/6 p-5 pl-0 border-r font-medium text-[11px] xl:text-xs relative">
          <div className="sticky top-24 flex flex-col gap-5 ">
            <div
              className={`p-3 rounded-md text-white hover:shadow-md cursor-pointer ${
                active === 1 ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive(1)}
            >
              Company Sales Goals
            </div>
            <div
              className={`p-3 rounded-md text-white hover:shadow-md cursor-pointer ${
                active === 2 ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive(2)}
            >
              Allocated Sales Goal Weightings <br /> (Sales Years & Factbase
              Metric)
              {/* Allocated Sales Goal Weighting */}
            </div>
            <div
              className={`p-3 rounded-md text-white hover:shadow-md cursor-pointer ${
                active === 3 ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive(3)}
            >
              Member Advisor Metrics <br /> (Share of sales, productivity level,
              and staffing)
              {/* Member Advisor Staffing and Productivity Levels by Tenure */}
            </div>
            <div
              className={`p-3 rounded-md text-white hover:shadow-md cursor-pointer ${
                active === 4 ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive(4)}
            >
              Contract for Success Objectives
            </div>
          </div>
        </div>
        {/* All forms break down to 6 different forms */}
        <div className="w-9/12 xl:w-5/6 h-full">
          {/* Company Sales Goals (as reported to Board) */}
          <div
            className={`p-5 border shadow rounded-lg w-full h-full ${
              active === 1 ? "" : "hidden"
            }`}
          >
            <form
              onSubmit={(e) => submitForm(e)}
              className="flex flex-col gap-5 h-full"
            >
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mb-5">
                  Company Sales Goals (as reported to Board)
                </h3>
                <div className="flex justify-end items-center gap-5">
                  <div
                    onClick={() => triggerDefaultValue("CSG_Inputs")}
                    className="p-2 border rounded-md cursor-pointer"
                  >
                    Default Values
                  </div>
                  <div className="border-b-2 border-orange-400 p-1 text-xs italic text-orange-400">
                    Inputs
                  </div>
                </div>
              </div>
              {/* Inputs */}
              <div className="flex gap-20">
                {/* Goal Year */}
                <div className="flex gap-5 items-center my-2">
                  <label className="" htmlFor="goal_year">
                    Goal Year
                  </label>
                  <span className="p-2 w-fit text-center font-semibold">
                    {CSG_Inputs.goal_year}
                  </span>
                  {/* <input
                    type="text"
                    name="goal_year"
                    id="goal_year"
                    maxLength={4}
                    className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                    onChange={(e) => handleOnInputChange(e)}
                    value={CSG_Inputs.goal_year || ""}
                  /> */}
                </div>
                {/* New Members */}
                <div className="flex gap-5 items-center my-2">
                  <label className="" htmlFor="new_members">
                    New Members
                  </label>
                  <input
                    type="text"
                    name="new_members"
                    id="new_members"
                    maxLength={10}
                    className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                    onChange={(e) => handleNumberChange(e)}
                    value={NumberFormatter(CSG_Inputs.new_members) || ""}
                  />
                </div>
              </div>
              {/* Table */}
              <div className="border rounded-md overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={2}
                        className="px-2 py-4 font-semibold text-left"
                      >
                        Goal Metric
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Life non-SPYT
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Life SPYT
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Life
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Annuity
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Med Supp
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* First Row of Annualized Sales Premium */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Annualized Sales Premium
                      </td>
                      {/* Life Non-SPYT */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="annualized_sales_prem_non_spyt"
                          id="annualized_sales_prem_non_spyt"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CSG_Inputs.annualized_sales_prem_non_spyt
                            ) || ""
                          }
                        />
                      </td>
                      {/* Life SPYT */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="annualized_sales_prem_spyt"
                          id="annualized_sales_prem_spyt"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CSG_Inputs.annualized_sales_prem_spyt
                            ) || ""
                          }
                        />
                      </td>
                      {/* Life */}
                      <td className="w-32 px-1">
                        <span className="p-2 w-full text-center">
                          {CurrencyFormatter(
                            CSG_Inputs.annualized_sales_prem_spyt +
                              CSG_Inputs.annualized_sales_prem_non_spyt
                          )}
                        </span>
                      </td>
                      {/* Annuity */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="annualized_sales_prem_annutiy"
                          id="annualized_sales_prem_annutiy"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CSG_Inputs.annualized_sales_prem_annutiy
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="annualized_sales_prem_med"
                          id="annualized_sales_prem_med"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CSG_Inputs.annualized_sales_prem_med
                            ) || ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Second Row of Sold Apps / Policies */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Sold Apps / Policies
                      </td>

                      {/* Life Non-SPYT */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="sold_apps_non_spyt"
                          id="sold_apps_non_spyt"
                          maxLength={10}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CSG_Inputs.sold_apps_non_spyt) || ""
                          }
                        />
                      </td>
                      {/* Life SPYT */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="sold_apps_spyt"
                          id="sold_apps_spyt"
                          maxLength={10}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CSG_Inputs.sold_apps_spyt) || ""
                          }
                        />
                      </td>
                      {/* Life */}
                      <td className="w-32 px-1">
                        <span className=" p-2 w-full text-center">
                          {NumberFormatter(
                            CSG_Inputs.sold_apps_non_spyt +
                              CSG_Inputs.sold_apps_spyt
                          )}
                        </span>
                      </td>
                      {/* Annuity */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="sold_apps_annuity"
                          id="sold_apps_annuity"
                          maxLength={10}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CSG_Inputs.sold_apps_annuity) || ""
                          }
                        />
                      </td>
                      {/* Med Supp */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="sold_apps_med"
                          id="sold_apps_med"
                          maxLength={10}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-full text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CSG_Inputs.sold_apps_med) || ""
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Actions */}
              <div className="flex justify-between my-5">
                {/* Prev and Next */}
                <div className="flex gap-5 justify-between">
                  <div
                    onClick={() => setActive(2)}
                    className="flex items-center justify-center gap-2 w-32 px-3 py-2 rounded-md bg-tertiary hover:bg-opacity-80 text-white cursor-pointer"
                  >
                    Next <IoMdArrowRoundBack className="rotate-180" />
                  </div>
                </div>
                {/* Submit and View Output */}
                <div className="flex gap-5 justify-between">
                  {load ? (
                    <span className="flex items-center justify-center gap-2 px-5 text-center py-2 rounded-md bg-gray-600 cursor-pointer text-white">
                      <LuLoader2 className="text-xl animate-spin" /> Processing
                    </span>
                  ) : (
                    <input
                      type="submit"
                      onClick={(e) => submitForm(e)}
                      value={"Submit"}
                      className="flex items-center justify-center gap-2 w-32 px-5 text-center py-2 rounded-md bg-primary cursor-pointer text-white"
                    />
                  )}
                  <div
                    onClick={() =>
                      showOutput ? setPage("Sales Goal Outputs") : ""
                    }
                    className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white ${
                      showOutput
                        ? "bg-orange-500 cursor-pointer"
                        : "bg-orange-200"
                    }`}
                  >
                    Show Output
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Sales Year Weighting (to calculate weighted average sales) */}
          <div
            className={`p-5 border shadow rounded-lg w-full h-full ${
              active === 2 ? "" : "hidden"
            }`}
          >
            <form
              onSubmit={(e) => {
                if (
                  totalHistoricalSales === 100 &&
                  totalMarketSize === 100 &&
                  totalPW === 100 &&
                  totalSPW === 100
                ) {
                  submitForm(e);
                } else {
                  e.preventDefault();
                }
              }}
              className="flex flex-col gap-5 h-full"
            >
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mb-5">
                  Sales Year Weighting (to calculate weighted average sales)
                </h3>
                <div className="flex justify-end items-center gap-5">
                  <div
                    onClick={() => triggerDefaultValue("SYW_Inputs")}
                    className="p-2 border rounded-md cursor-pointer"
                  >
                    Default Values
                  </div>
                  <div className="border-b-2 border-orange-400 p-1 text-xs italic text-orange-400">
                    Inputs
                  </div>
                </div>
              </div>
              {/* Table for Sales Year Weighting */}
              <div className="border rounded-md overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={2}
                        className="px-2 py-4 font-semibold text-left"
                      >
                        Parameter
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        {CSG_Inputs.goal_year - 3}
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        {CSG_Inputs.goal_year - 2}
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        {CSG_Inputs.goal_year - 1}
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* First Row of Annualized Sales Premium */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Annualized Sales Premium
                      </td>
                      {/* Goal Year -3 */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="historical_sales_1"
                          id="historical_sales_1"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={SYW_Inputs.historical_sales_1 + "%" || ""}
                        />
                      </td>
                      {/* Goal Year -2 */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="historical_sales_2"
                          id="historical_sales_2"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={SYW_Inputs.historical_sales_2 + "%" || ""}
                        />
                      </td>
                      {/* Goal Year -1 */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="historical_sales_3"
                          id="historical_sales_3"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={SYW_Inputs.historical_sales_3 + "%" || ""}
                        />
                      </td>
                      {/* Total */}
                      <td className="w-32 px-1">
                        <span
                          className={`p-2 w-32 text-center font-bold ${
                            SYW_Inputs.historical_sales_1 +
                              SYW_Inputs.historical_sales_2 +
                              SYW_Inputs.historical_sales_3 ===
                            100
                              ? ""
                              : "text-red-500"
                          }`}
                        >
                          {SYW_Inputs.historical_sales_1 +
                            SYW_Inputs.historical_sales_2 +
                            SYW_Inputs.historical_sales_3 +
                            "%"}
                        </span>
                      </td>
                    </tr>
                    {/* Second Row of Sold Apps / Policies */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Sold Apps / Policies
                      </td>
                      {/* Goal Year -3 */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="market_size_1"
                          id="market_size_1"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={SYW_Inputs.market_size_1 + "%" || ""}
                        />
                      </td>
                      {/* Goal Year -2 */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="market_size_2"
                          id="market_size_2"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={SYW_Inputs.market_size_2 + "%" || ""}
                        />
                      </td>
                      {/* Goal Year -1 */}
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="market_size_3"
                          id="market_size_3"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={SYW_Inputs.market_size_3 + "%" || ""}
                        />
                      </td>
                      {/* Total */}
                      <td className="w-32 px-1">
                        <span
                          className={`p-2 w-32 text-center font-bold ${
                            SYW_Inputs.market_size_1 +
                              SYW_Inputs.market_size_2 +
                              SYW_Inputs.market_size_3 ===
                            100
                              ? ""
                              : "text-red-500"
                          }`}
                        >
                          {SYW_Inputs.market_size_1 +
                            SYW_Inputs.market_size_2 +
                            SYW_Inputs.market_size_3 +
                            "%"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Allocated Sales Goal Weighting (by Factbase Metric) */}
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mt-5">
                  Allocated Sales Goal Weighting (by Factbase Metric)
                </h3>
              </div>
              <div className="flex gap-5 justify-between">
                {/* Table 1 */}
                <div className="border rounded-md overflow-auto w-1/2">
                  <table className="w-full">
                    <thead>
                      <tr className="bg-cyan-800 text-white">
                        <th
                          colSpan={2}
                          className="px-2 py-4 font-semibold text-left"
                        >
                          Factbase Metric
                        </th>
                        <th
                          colSpan={1}
                          className="px-2 py-4 font-semibold border-l w-48"
                        >
                          Premium Weighting
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* First Row of CUF Historical: Sales Prem */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Historical: Sales Prem
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="historical_sales_pw"
                            id="historical_sales_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.historical_sales_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Second Row of CUF Historical: Sold Pols */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Historical: Sold Pols
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="historical_sold_pw"
                            id="historical_sold_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.historical_sold_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Third Row of Market Size: Sales Prem */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Market Size: Sales Prem
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="market_size_sales_pw"
                            id="market_size_sales_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.market_size_sales_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Fourth Row of Market Size: Sold Pols */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Market Size: Sold Pols
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="market_size_sold_pw"
                            id="market_size_sold_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.market_size_sold_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Fifth Row of CUF Inforce: Premium */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Inforce: Premium
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="inforce_premium_pw"
                            id="inforce_premium_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.inforce_premium_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Sixth Row of CUF Inforce: Members */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Inforce: Members
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="inforce_members_pw"
                            id="inforce_members_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.inforce_members_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Seventh Row of Catholic Population */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Catholic Population
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="catholic_population_pw"
                            id="catholic_population_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={
                              ASGW_Inputs.catholic_population_pw + "%" || ""
                            }
                          />
                        </td>
                      </tr>
                      {/* Eighth Row of Catholic Parishes */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Catholic Parishes
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="catholic_parishes_pw"
                            id="catholic_parishes_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.catholic_parishes_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Ninth Row of CUF Historical: Grants Paid */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Historical: Grants Paid
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="historical_grants_pw"
                            id="historical_grants_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.historical_grants_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Tenth Row of CUF Member Advisors */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Member Advisors
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="member_advisors_pw"
                            id="member_advisors_pw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.member_advisors_pw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Total */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-bold px-2 py-4 text-left"
                        >
                          Total
                        </td>
                        {/* Premium Weighting */}
                        <td className="w-32 px-1">
                          <span
                            className={`w-32 text-center font-bold ${
                              totalPW === 100 ? "" : "text-red-500"
                            }`}
                          >
                            {totalPW + "%"}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Table 2*/}
                <div className="border rounded-md overflow-auto w-1/2">
                  <table className="w-full">
                    <thead>
                      <tr className="bg-cyan-800 text-white">
                        <th
                          colSpan={2}
                          className="px-2 py-4 font-semibold text-left"
                        >
                          Factbase Metric
                        </th>
                        <th
                          colSpan={1}
                          className="px-2 py-4 font-semibold border-l w-48"
                        >
                          Sold Policy Weighting
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* First Row of CUF Historical: Sales Prem */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Historical: Sales Prem
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="historical_sales_spw"
                            id="historical_sales_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.historical_sales_spw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Second Row of CUF Historical: Sold Pols */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Historical: Sold Pols
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="historical_sold_spw"
                            id="historical_sold_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.historical_sold_spw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Third Row of Market Size: Sales Prem */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Market Size: Sales Prem
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="market_size_sales_spw"
                            id="market_size_sales_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={
                              ASGW_Inputs.market_size_sales_spw + "%" || ""
                            }
                          />
                        </td>
                      </tr>
                      {/* Fourth Row of Market Size: Sold Pols */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Market Size: Sold Pols
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="market_size_sold_spw"
                            id="market_size_sold_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.market_size_sold_spw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Fifth Row of CUF Inforce: Premium */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Inforce: Premium
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="inforce_premium_spw"
                            id="inforce_premium_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.inforce_premium_spw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Sixth Row of CUF Inforce: Members */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Inforce: Members
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="inforce_members_spw"
                            id="inforce_members_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.inforce_members_spw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Seventh Row of Catholic Population */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Catholic Population
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="catholic_population_spw"
                            id="catholic_population_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={
                              ASGW_Inputs.catholic_population_spw + "%" || ""
                            }
                          />
                        </td>
                      </tr>
                      {/* Eighth Row of Catholic Parishes */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          Catholic Parishes
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="catholic_parishes_spw"
                            id="catholic_parishes_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={
                              ASGW_Inputs.catholic_parishes_spw + "%" || ""
                            }
                          />
                        </td>
                      </tr>
                      {/* Ninth Row of CUF Historical: Grants Paid */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Historical: Grants Paid
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="historical_grants_spw"
                            id="historical_grants_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={
                              ASGW_Inputs.historical_grants_spw + "%" || ""
                            }
                          />
                        </td>
                      </tr>
                      {/* Tenth Row of CUF Member Advisors */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-medium px-2 py-4 text-left"
                        >
                          CUF Member Advisors
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <input
                            type="text"
                            name="member_advisors_spw"
                            id="member_advisors_spw"
                            maxLength={4}
                            className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                            onChange={(e) => handlePercentageChange(e)}
                            onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                            value={ASGW_Inputs.member_advisors_spw + "%" || ""}
                          />
                        </td>
                      </tr>
                      {/* Total */}
                      <tr className="text-center">
                        <td
                          colSpan={2}
                          className="font-bold px-2 py-4 text-left"
                        >
                          Total
                        </td>
                        {/* Sold Policy Weighting */}
                        <td className="w-32 px-1">
                          <span
                            className={`w-32 text-center font-bold ${
                              totalSPW === 100 ? "" : "text-red-500"
                            }`}
                          >
                            {totalSPW + "%"}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Error Message */}
              {totalHistoricalSales === 100 &&
              totalMarketSize === 100 &&
              totalPW === 100 &&
              totalSPW === 100 ? (
                ""
              ) : (
                <div className="flex justify-end">
                  <span className="text-red-500 font-bold my-5">
                    Ensure total weightings should always be 100%
                  </span>
                </div>
              )}
              {/* Actions */}
              <div className="flex justify-between mb-5">
                {/* Prev and Next */}
                <div className="flex gap-5 justify-between">
                  <div
                    onClick={() => setActive(1)}
                    className="flex items-center justify-center gap-2 w-32 px-3 py-2 rounded-md bg-tertiary hover:bg-opacity-80 text-white cursor-pointer"
                  >
                    <IoMdArrowRoundBack /> Previous
                  </div>
                  <div
                    onClick={() => setActive(3)}
                    className="flex items-center justify-center gap-2 w-32 px-3 py-2 rounded-md bg-tertiary hover:bg-opacity-80 text-white cursor-pointer"
                  >
                    Next <IoMdArrowRoundBack className="rotate-180" />
                  </div>
                </div>
                <div className="flex gap-5 justify-between">
                  {load ? (
                    <span className="flex items-center justify-center gap-2 px-5 text-center py-2 rounded-md bg-gray-600 cursor-pointer text-white">
                      <LuLoader2 className="text-xl animate-spin" /> Processing
                    </span>
                  ) : (
                    <input
                      type="submit"
                      onClick={(e) => {
                        if (
                          totalHistoricalSales === 100 &&
                          totalMarketSize === 100 &&
                          totalPW === 100 &&
                          totalSPW === 100
                        ) {
                          submitForm(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                      value={"Submit"}
                      className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md bg-primary text-white ${
                        totalHistoricalSales === 100 &&
                        totalMarketSize === 100 &&
                        totalPW === 100 &&
                        totalSPW === 100
                          ? "cursor-pointer"
                          : "bg-opacity-60"
                      }`}
                    />
                  )}
                  <div
                    onClick={() =>
                      showOutput ? setPage("Sales Goal Outputs") : ""
                    }
                    className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white ${
                      showOutput
                        ? "bg-orange-500 cursor-pointer"
                        : "bg-orange-200"
                    }`}
                  >
                    Show Output
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Share of Member Advisor Sales */}
          <div
            className={`p-5 border shadow rounded-lg w-full h-full ${
              active === 3 ? "" : "hidden"
            }`}
          >
            <form
              onSubmit={(e) => {
                if (SMA_Inputs.sales_from_member_advisors <= 100) {
                  submitForm(e);
                } else {
                  e.preventDefault();
                }
              }}
              className="flex flex-col gap-5 h-full"
            >
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mb-5">
                  Share of Member Advisor Sales
                </h3>
                <div className="flex justify-end items-center gap-5">
                  <div
                    onClick={() => triggerDefaultValue("SMA_Inputs")}
                    className="p-2 border rounded-md cursor-pointer"
                  >
                    Default Values
                  </div>
                  <div className="border-b-2 border-orange-400 p-1 text-xs italic text-orange-400">
                    Inputs
                  </div>
                </div>
              </div>
              {/* Table */}
              <div className="border rounded-md overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={2}
                        className="px-2 py-4 font-semibold text-left"
                      >
                        Source
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        % of Sales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* First Row of Sales from Member Advisors */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Sales from Member Advisors
                      </td>
                      <td className="w-32 px-1">
                        <input
                          type="text"
                          name="sales_from_member_advisors"
                          id="sales_from_member_advisors"
                          maxLength={4}
                          className={`border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center ${
                            SMA_Inputs.sales_from_member_advisors > 100
                              ? "text-red-500"
                              : ""
                          }`}
                          onChange={(e) => handlePercentageChange(e)}
                          onKeyDown={(e) => handleBackspaceForPercentage(e, changeStateForPercent)}
                          value={
                            SMA_Inputs.sales_from_member_advisors + "%" ||
                            "" + "%"
                          }
                        />
                      </td>
                    </tr>
                    {/* Second Row of Sales from Other (non-Advisors) */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Sales from Other (non-Advisors)
                      </td>
                      <td className="w-32 px-1">
                        <input
                          type="disabled"
                          disabled
                          name="sales_from_other"
                          className={`p-2 w-fit text-center cursor-text font-bold bg-transparent ${
                            SMA_Inputs.sales_from_member_advisors > 100
                              ? "text-red-500"
                              : ""
                          }`}
                          value={
                            SMA_Inputs.total_sales -
                            SMA_Inputs.sales_from_member_advisors +
                            "%"
                          }
                        />
                      </td>
                    </tr>
                    {/* Third Row of Total */}
                    <tr className="text-center">
                      <td
                        colSpan={2}
                        className="font-medium px-2 py-4 text-left"
                      >
                        Total
                      </td>
                      <td className="w-32 px-1">
                        <span
                          type="text"
                          name="historical_sales_total"
                          className={`w-32 text-center font-bold `}
                        >
                          {SMA_Inputs.total_sales + "%"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Member Advisor Productivity Levels by Tenure */}
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mt-5">
                  Member Advisor Productivity Levels by Tenure
                </h3>
              </div>
              {/* Table 1*/}
              <div className="border rounded-md overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={1}
                        rowSpan={2}
                        className="px-2 py-4 font-semibold text-left"
                      >
                        Region / Team
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        2024 Plan
                      </th>
                      <th
                        colSpan={3}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Current Reps
                      </th>
                    </tr>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        New Hires
                      </th>
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        {"<"}2 Yr Tenure
                      </th>
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        2-5 Yr Tenure
                      </th>
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        5+ Yr Tenure
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {/* Row of Policy Per Member Advisor */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold text-left">
                          Policy Per Member Advisor
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="policies_per_member_nh"
                          id="policies_per_member_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              MAS_Inputs.policies_per_member_nh
                            ) || ""
                          }
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="policies_per_member_2less"
                          id="policies_per_member_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              MAS_Inputs.policies_per_member_2less
                            ) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="policies_per_member_2_5"
                          id="policies_per_member_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              MAS_Inputs.policies_per_member_2_5
                            ) || ""
                          }
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="policies_per_member_5more"
                          id="policies_per_member_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              MAS_Inputs.policies_per_member_5more
                            ) || ""
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Member Advisor Staffing by Tenure */}
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mt-5">
                  Member Advisor Staffing by Tenure
                </h3>
              </div>
              {/* Table 2*/}
              <div className="border rounded-md overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={1}
                        rowSpan={2}
                        className="px-2 py-4 font-semibold text-left"
                      >
                        Region / Team
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        2024 Plan
                      </th>
                      <th
                        colSpan={3}
                        className="px-2 py-4 font-semibold border-l"
                      >
                        Current Reps
                      </th>
                      <th
                        colSpan={1}
                        rowSpan={2}
                        className="px-2 py-4 font-semibold border-l w-52"
                      >
                        Total Current Reps
                      </th>
                    </tr>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        New Hires
                      </th>
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        {"<"}2 Yr Tenure
                      </th>
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        2-5 Yr Tenure
                      </th>
                      <th
                        colSpan={1}
                        className="p-2 font-semibold border-l border-t"
                      >
                        5+ Yr Tenure
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {/* First Row of Central */}
                    <tr>
                      <td className="font-bold px-2 py-4 text-left">Central</td>
                      {/* New Hires */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          MAS_Inputs.lakeland_nh +
                            MAS_Inputs.maple_trail_nh +
                            MAS_Inputs.northwoods_nh
                        )}
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2less +
                            MAS_Inputs.maple_trail_2less +
                            MAS_Inputs.northwoods_2less
                        )}
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2_5 +
                            MAS_Inputs.maple_trail_2_5 +
                            MAS_Inputs.northwoods_2_5
                        )}
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          MAS_Inputs.lakeland_5more +
                            MAS_Inputs.maple_trail_5more +
                            MAS_Inputs.northwoods_5more
                        )}
                      </td>
                      {/* Total */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2less +
                            MAS_Inputs.maple_trail_2less +
                            MAS_Inputs.northwoods_2less +
                            MAS_Inputs.lakeland_2_5 +
                            MAS_Inputs.maple_trail_2_5 +
                            MAS_Inputs.northwoods_2_5 +
                            MAS_Inputs.lakeland_5more +
                            MAS_Inputs.maple_trail_5more +
                            MAS_Inputs.northwoods_5more
                        )}
                      </td>
                    </tr>
                    {/* Second Row of Lakeland */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Lakeland
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="lakeland_nh"
                          id="lakeland_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={NumberFormatter(MAS_Inputs.lakeland_nh) || ""}
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="lakeland_2less"
                          id="lakeland_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.lakeland_2less) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="lakeland_2_5"
                          id="lakeland_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={NumberFormatter(MAS_Inputs.lakeland_2_5) || ""}
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="lakeland_5more"
                          id="lakeland_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.lakeland_5more) || ""
                          }
                        />
                      </td>
                      {/* Total */}
                      <td
                        className={` focus:outline-none px-2 py-[10px] w-32 text-center font-bold`}
                      >
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2less +
                            MAS_Inputs.lakeland_2_5 +
                            MAS_Inputs.lakeland_5more
                        )}
                      </td>
                    </tr>
                    {/* Third Row of Maple Trail */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Maple Trail
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="maple_trail_nh"
                          id="maple_trail_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.maple_trail_nh) || ""
                          }
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="maple_trail_2less"
                          id="maple_trail_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.maple_trail_2less) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="maple_trail_2_5"
                          id="maple_trail_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.maple_trail_2_5) || ""
                          }
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="maple_trail_5more"
                          id="maple_trail_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.maple_trail_5more) || ""
                          }
                        />
                      </td>
                      {/* Total */}
                      <td
                        className={` focus:outline-none px-2 py-[10px] w-32 text-center font-bold`}
                      >
                        {NumberFormatter(
                          MAS_Inputs.maple_trail_2less +
                            MAS_Inputs.maple_trail_2_5 +
                            MAS_Inputs.maple_trail_5more
                        )}
                      </td>
                    </tr>
                    {/* Fourth Row of Northwoods */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Northwoods
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="northwoods_nh"
                          id="northwoods_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.northwoods_nh) || ""
                          }
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="northwoods_2less"
                          id="northwoods_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.northwoods_2less) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="northwoods_2_5"
                          id="northwoods_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.northwoods_2_5) || ""
                          }
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="northwoods_5more"
                          id="northwoods_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.northwoods_5more) || ""
                          }
                        />
                      </td>
                      {/* Total */}
                      <td
                        className={` focus:outline-none px-2 py-[10px] w-32 text-center font-bold`}
                      >
                        {NumberFormatter(
                          MAS_Inputs.northwoods_2less +
                            MAS_Inputs.northwoods_2_5 +
                            MAS_Inputs.northwoods_5more
                        )}
                      </td>
                    </tr>
                    {/* Fifth Row of Southwest */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-bold text-left">Southwest</h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.countryside_nh +
                            MAS_Inputs.praire_view_nh +
                            MAS_Inputs.river_ridge_nh
                        )}
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.countryside_2less +
                            MAS_Inputs.praire_view_2less +
                            MAS_Inputs.river_ridge_2less
                        )}
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.countryside_2_5 +
                            MAS_Inputs.praire_view_2_5 +
                            MAS_Inputs.river_ridge_2_5
                        )}
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.countryside_5more +
                            MAS_Inputs.praire_view_5more +
                            MAS_Inputs.river_ridge_5more
                        )}
                      </td>
                      {/* Total */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.countryside_2less +
                            MAS_Inputs.praire_view_2less +
                            MAS_Inputs.river_ridge_2less +
                            MAS_Inputs.countryside_2_5 +
                            MAS_Inputs.praire_view_2_5 +
                            MAS_Inputs.river_ridge_2_5 +
                            MAS_Inputs.countryside_5more +
                            MAS_Inputs.praire_view_5more +
                            MAS_Inputs.river_ridge_5more
                        )}
                      </td>
                    </tr>
                    {/* Sixth Row of Countryside */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Countryside
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="countryside_nh"
                          id="countryside_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.countryside_nh) || ""
                          }
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="countryside_2less"
                          id="countryside_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.countryside_2less) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="countryside_2_5"
                          id="countryside_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.countryside_2_5) || ""
                          }
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="countryside_5more"
                          id="countryside_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.countryside_5more) || ""
                          }
                        />
                      </td>
                      {/* Total */}
                      <td
                        className={` focus:outline-none px-2 py-[10px] w-32 text-center font-bold`}
                      >
                        {NumberFormatter(
                          MAS_Inputs.countryside_2less +
                            MAS_Inputs.countryside_2_5 +
                            MAS_Inputs.countryside_5more
                        )}
                      </td>
                    </tr>
                    {/* Seventh Row of Praire View */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Praire View
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="praire_view_nh"
                          id="praire_view_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.praire_view_nh) || ""
                          }
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="praire_view_2less"
                          id="praire_view_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.praire_view_2less) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="praire_view_2_5"
                          id="praire_view_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.praire_view_2_5) || ""
                          }
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="praire_view_5more"
                          id="praire_view_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.praire_view_5more) || ""
                          }
                        />
                      </td>
                      {/* Total */}
                      <td
                        className={` focus:outline-none px-2 py-[10px] w-32 text-center font-bold`}
                      >
                        {NumberFormatter(
                          MAS_Inputs.praire_view_2less +
                            MAS_Inputs.praire_view_2_5 +
                            MAS_Inputs.praire_view_5more
                        )}
                      </td>
                    </tr>
                    {/* Eighth Row of River Ridge */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          River Ridge
                        </h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="river_ridge_nh"
                          id="river_ridge_nh"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.river_ridge_nh) || ""
                          }
                        />
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="river_ridge_2less"
                          id="river_ridge_2less"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.river_ridge_2less) || ""
                          }
                        />
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="river_ridge_2_5"
                          id="river_ridge_2_5"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.river_ridge_2_5) || ""
                          }
                        />
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-[10px]">
                        <input
                          type="text"
                          name="river_ridge_5more"
                          id="river_ridge_5more"
                          maxLength={4}
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(MAS_Inputs.river_ridge_5more) || ""
                          }
                        />
                      </td>
                      {/* Total */}
                      <td
                        className={` focus:outline-none px-2 py-[10px] w-32 text-center font-bold`}
                      >
                        {NumberFormatter(
                          MAS_Inputs.river_ridge_2less +
                            MAS_Inputs.river_ridge_2_5 +
                            MAS_Inputs.river_ridge_5more
                        )}
                      </td>
                    </tr>
                    {/* Last Row of Total */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-bold text-left">Total</h4>
                      </td>
                      {/* New Hires */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.lakeland_nh +
                            MAS_Inputs.maple_trail_nh +
                            MAS_Inputs.northwoods_nh +
                            MAS_Inputs.countryside_nh +
                            MAS_Inputs.praire_view_nh +
                            MAS_Inputs.river_ridge_nh
                        )}
                      </td>
                      {/* <2 Yr Tenure */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2less +
                            MAS_Inputs.maple_trail_2less +
                            MAS_Inputs.northwoods_2less +
                            MAS_Inputs.countryside_2less +
                            MAS_Inputs.praire_view_2less +
                            MAS_Inputs.river_ridge_2less
                        )}
                      </td>
                      {/* 2-5 Yr Tenure */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2_5 +
                            MAS_Inputs.maple_trail_2_5 +
                            MAS_Inputs.northwoods_2_5 +
                            MAS_Inputs.countryside_2_5 +
                            MAS_Inputs.praire_view_2_5 +
                            MAS_Inputs.river_ridge_2_5
                        )}
                      </td>
                      {/* 5+ Yr Tenure */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.lakeland_5more +
                            MAS_Inputs.maple_trail_5more +
                            MAS_Inputs.northwoods_5more +
                            MAS_Inputs.countryside_5more +
                            MAS_Inputs.praire_view_5more +
                            MAS_Inputs.river_ridge_5more
                        )}
                      </td>
                      {/* Total */}
                      <td className="px-2 py-4 w-32 font-bold">
                        {NumberFormatter(
                          MAS_Inputs.lakeland_2less +
                            MAS_Inputs.maple_trail_2less +
                            MAS_Inputs.northwoods_2less +
                            MAS_Inputs.lakeland_2_5 +
                            MAS_Inputs.maple_trail_2_5 +
                            MAS_Inputs.northwoods_2_5 +
                            MAS_Inputs.lakeland_5more +
                            MAS_Inputs.maple_trail_5more +
                            MAS_Inputs.northwoods_5more +
                            MAS_Inputs.countryside_2less +
                            MAS_Inputs.praire_view_2less +
                            MAS_Inputs.river_ridge_2less +
                            MAS_Inputs.countryside_2_5 +
                            MAS_Inputs.praire_view_2_5 +
                            MAS_Inputs.river_ridge_2_5 +
                            MAS_Inputs.countryside_5more +
                            MAS_Inputs.praire_view_5more +
                            MAS_Inputs.river_ridge_5more
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Error Message */}
              {SMA_Inputs.sales_from_member_advisors > 100 ? (
                <div className="flex justify-end">
                  <span className="text-red-500 font-bold mt-5">
                    Ensure sales from member advisors does not exceeds 100%
                  </span>
                </div>
              ) : (
                ""
              )}
              {/* Actions */}
              <div className="flex justify-between my-5">
                {/* Prev and Next */}
                <div className="flex gap-5 justify-between">
                  <div
                    onClick={() => setActive(2)}
                    className="flex items-center justify-center gap-2 w-32 px-3 py-2 rounded-md bg-tertiary hover:bg-opacity-80 text-white cursor-pointer"
                  >
                    <IoMdArrowRoundBack /> Previous
                  </div>
                  <div
                    onClick={() => setActive(4)}
                    className="flex items-center justify-center gap-2 w-32 px-3 py-2 rounded-md bg-tertiary hover:bg-opacity-80 text-white cursor-pointer"
                  >
                    Next <IoMdArrowRoundBack className="rotate-180" />
                  </div>
                </div>
                <div className="flex gap-5 justify-between">
                  {load ? (
                    <span className="flex items-center justify-center gap-2 px-5 text-center py-2 rounded-md bg-gray-600 cursor-pointer text-white">
                      <LuLoader2 className="text-xl animate-spin" /> Processing
                    </span>
                  ) : (
                    <input
                      type="submit"
                      onClick={(e) => {
                        if (SMA_Inputs.sales_from_member_advisors <= 100) {
                          submitForm(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                      value={"Submit"}
                      className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md bg-primary text-white ${
                        SMA_Inputs.sales_from_member_advisors <= 100
                          ? "cursor-pointer"
                          : "bg-opacity-60"
                      }`}
                    />
                  )}
                  <div
                    onClick={() =>
                      showOutput ? setPage("Sales Goal Outputs") : ""
                    }
                    className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white ${
                      showOutput
                        ? "bg-orange-500 cursor-pointer"
                        : "bg-orange-200"
                    }`}
                  >
                    Show Output
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Contract for Success Objectives (for Comparison Purposes Only) */}
          <div
            className={`p-5 border shadow rounded-lg w-full h-full ${
              active === 4 ? "" : "hidden"
            }`}
          >
            <form
              onSubmit={(e) => submitForm(e)}
              className="flex flex-col gap-5 h-full"
            >
              {/* Heading */}
              <div className="flex justify-between items-start">
                <h3 className="font-bold mb-5">
                  Contract for Success Objectives (for Comparison Purposes Only)
                </h3>
                <div className="flex justify-end items-center gap-5">
                  <div
                    onClick={() => triggerDefaultValue("CC_Inputs")}
                    className="p-2 border rounded-md cursor-pointer"
                  >
                    Default Values
                  </div>
                  <div className="border-b-2 border-orange-400 p-1 text-xs italic text-orange-400">
                    Inputs
                  </div>
                </div>
              </div>
              {/* Table */}
              <div className="border rounded-md overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={1}
                        rowSpan={2}
                        className="px-2 py-2 font-semibold text-left"
                      >
                        Region / Team
                      </th>
                      <th
                        colSpan={2}
                        className="px-2 py-2 font-semibold border-l"
                      >
                        Life
                      </th>
                      <th
                        colSpan={2}
                        className="px-2 py-2 font-semibold border-l"
                      >
                        Annuity
                      </th>
                      <th
                        colSpan={2}
                        className="px-2 py-2 font-semibold border-l"
                      >
                        Med Supp
                      </th>
                    </tr>
                    <tr className="bg-cyan-800 text-white">
                      <th
                        colSpan={1}
                        className="px-2 py-2 font-semibold border-l border-t"
                      >
                        Premium
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-2 font-semibold border-l border-t"
                      >
                        Policies
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-2 font-semibold border-l border-t"
                      >
                        Premium
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-2 font-semibold border-l border-t"
                      >
                        Policies
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-2 font-semibold border-l border-t"
                      >
                        Premium
                      </th>
                      <th
                        colSpan={1}
                        className="px-2 py-2 font-semibold border-l border-t"
                      >
                        Policies
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {/* First Row of Central */}
                    <tr>
                      <td className="font-bold px-2 py-4 text-left">Central</td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.lakeland_life_prem +
                            CC_Inputs.maple_trail_life_prem +
                            CC_Inputs.northwoods_life_prem
                        )}
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.lakeland_life_pols +
                            CC_Inputs.maple_trail_life_pols +
                            CC_Inputs.northwoods_life_pols
                        )}
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.lakeland_annuity_prem +
                            CC_Inputs.maple_trail_annuity_prem +
                            CC_Inputs.northwoods_annuity_prem
                        )}
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.lakeland_annuity_pols +
                            CC_Inputs.maple_trail_annuity_pols +
                            CC_Inputs.northwoods_annuity_pols
                        )}
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.lakeland_med_prem +
                            CC_Inputs.maple_trail_med_prem +
                            CC_Inputs.northwoods_med_prem
                        )}
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.lakeland_med_pols +
                            CC_Inputs.maple_trail_med_pols +
                            CC_Inputs.northwoods_med_pols
                        )}
                      </td>
                    </tr>
                    {/* Second Row of Lakeland */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Lakeland
                        </h4>
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_life_prem"
                          id="lakeland_life_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.lakeland_life_prem) ||
                            ""
                          }
                        />
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_life_pols"
                          id="lakeland_life_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.lakeland_life_pols) || ""
                          }
                        />
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_annuity_prem"
                          id="lakeland_annuity_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.lakeland_annuity_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_annuity_pols"
                          id="lakeland_annuity_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.lakeland_annuity_pols) ||
                            ""
                          }
                        />
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_med_prem"
                          id="lakeland_med_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.lakeland_med_prem) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_med_pols"
                          id="lakeland_med_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.lakeland_med_pols) || ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Third Row of Maple Trail */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Maple Trail
                        </h4>
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="maple_trail_life_prem"
                          id="maple_trail_life_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.maple_trail_life_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="maple_trail_life_pols"
                          id="maple_trail_life_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.maple_trail_life_pols) ||
                            ""
                          }
                        />
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="maple_trail_annuity_prem"
                          id="maple_trail_annuity_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.maple_trail_annuity_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="maple_trail_annuity_pols"
                          id="maple_trail_annuity_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              CC_Inputs.maple_trail_annuity_pols
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="maple_trail_med_prem"
                          id="maple_trail_med_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.maple_trail_med_prem) ||
                            ""
                          }
                        />
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="maple_trail_med_pols"
                          id="maple_trail_med_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.maple_trail_med_pols) ||
                            ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Fourth Row of Northwoods */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Northwoods
                        </h4>
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="northwoods_life_prem"
                          id="northwoods_life_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.northwoods_life_prem) ||
                            ""
                          }
                        />
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="northwoods_life_pols"
                          id="northwoods_life_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.northwoods_life_pols) ||
                            ""
                          }
                        />
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="northwoods_annuity_prem"
                          id="northwoods_annuity_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.northwoods_annuity_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="northwoods_annuity_pols"
                          id="northwoods_annuity_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              CC_Inputs.northwoods_annuity_pols
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="northwoods_med_prem"
                          id="northwoods_med_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.northwoods_med_prem) ||
                            ""
                          }
                        />
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="northwoods_med_pols"
                          id="northwoods_med_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.northwoods_med_pols) || ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Fifth Row of Southwest */}
                    <tr>
                      <td className="font-bold px-2 py-4 text-left">
                        Southwest
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.countryside_life_prem +
                            CC_Inputs.prairie_view_life_prem +
                            CC_Inputs.river_ridge_life_prem
                        )}
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.countryside_life_pols +
                            CC_Inputs.prairie_view_life_pols +
                            CC_Inputs.river_ridge_life_pols
                        )}
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.countryside_annuity_prem +
                            CC_Inputs.prairie_view_annuity_prem +
                            CC_Inputs.river_ridge_annuity_prem
                        )}
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.countryside_annuity_pols +
                            CC_Inputs.prairie_view_annuity_pols +
                            CC_Inputs.river_ridge_annuity_pols
                        )}
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.countryside_med_prem +
                            CC_Inputs.prairie_view_med_prem +
                            CC_Inputs.river_ridge_med_prem
                        )}
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.countryside_med_pols +
                            CC_Inputs.prairie_view_med_pols +
                            CC_Inputs.river_ridge_med_pols
                        )}
                      </td>
                    </tr>
                    {/* Sixth Row of Countryside */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Countryside
                        </h4>
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="countryside_life_prem"
                          id="countryside_life_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.countryside_life_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="countryside_life_pols"
                          id="countryside_life_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.countryside_life_pols) ||
                            ""
                          }
                        />
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="countryside_annuity_prem"
                          id="countryside_annuity_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.countryside_annuity_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="countryside_annuity_pols"
                          id="countryside_annuity_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              CC_Inputs.countryside_annuity_pols
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="countryside_med_prem"
                          id="countryside_med_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.countryside_med_prem) ||
                            ""
                          }
                        />
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="countryside_med_pols"
                          id="countryside_med_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.countryside_med_pols) ||
                            ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Seventh Row of Prairie View */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          Prairie View
                        </h4>
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="prairie_view_life_prem"
                          id="prairie_view_life_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.prairie_view_life_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="prairie_view_life_pols"
                          id="prairie_view_life_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.prairie_view_life_pols) ||
                            ""
                          }
                        />
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="prairie_view_annuity_prem"
                          id="prairie_view_annuity_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.prairie_view_annuity_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="prairie_view_annuity_pols"
                          id="prairie_view_annuity_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              CC_Inputs.prairie_view_annuity_pols
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="prairie_view_med_prem"
                          id="prairie_view_med_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.prairie_view_med_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="lakeland_med_pols"
                          id="lakeland_med_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.lakeland_med_pols) || ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Eighth Row of River Ridge */}
                    <tr>
                      <td className="p-2">
                        <h4 className="font-semibold ml-2 text-left">
                          River Ridge
                        </h4>
                      </td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="river_ridge_life_prem"
                          id="river_ridge_life_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.river_ridge_life_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="river_ridge_life_pols"
                          id="river_ridge_life_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.river_ridge_life_pols) ||
                            ""
                          }
                        />
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="river_ridge_annuity_prem"
                          id="river_ridge_annuity_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(
                              CC_Inputs.river_ridge_annuity_prem
                            ) || ""
                          }
                        />
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="river_ridge_annuity_pols"
                          id="river_ridge_annuity_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(
                              CC_Inputs.river_ridge_annuity_pols
                            ) || ""
                          }
                        />
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="river_ridge_med_prem"
                          id="river_ridge_med_prem"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleCurrencyChange(e)}
                          value={
                            CurrencyFormatter(CC_Inputs.river_ridge_med_prem) ||
                            ""
                          }
                        />
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32`}>
                        <input
                          type="text"
                          name="river_ridge_med_pols"
                          id="river_ridge_med_pols"
                          className="border-b-2 border-orange-400 focus:outline-none p-2 w-32 text-center"
                          onChange={(e) => handleNumberChange(e)}
                          value={
                            NumberFormatter(CC_Inputs.river_ridge_med_pols) ||
                            ""
                          }
                        />
                      </td>
                    </tr>
                    {/* Last Row of Total */}
                    <tr>
                      <td className="font-bold px-2 py-4 text-left">Total</td>
                      {/* Life Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.lakeland_life_prem +
                            CC_Inputs.maple_trail_life_prem +
                            CC_Inputs.northwoods_life_prem +
                            CC_Inputs.countryside_life_prem +
                            CC_Inputs.prairie_view_life_prem +
                            CC_Inputs.river_ridge_life_prem
                        )}
                      </td>
                      {/* Life Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.lakeland_life_pols +
                            CC_Inputs.maple_trail_life_pols +
                            CC_Inputs.northwoods_life_pols +
                            CC_Inputs.countryside_life_pols +
                            CC_Inputs.prairie_view_life_pols +
                            CC_Inputs.river_ridge_life_pols
                        )}
                      </td>
                      {/* Annuity Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.lakeland_annuity_prem +
                            CC_Inputs.maple_trail_annuity_prem +
                            CC_Inputs.northwoods_annuity_prem +
                            CC_Inputs.countryside_annuity_prem +
                            CC_Inputs.prairie_view_annuity_prem +
                            CC_Inputs.river_ridge_annuity_prem
                        )}
                      </td>
                      {/* Annuity Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.lakeland_annuity_pols +
                            CC_Inputs.maple_trail_annuity_pols +
                            CC_Inputs.northwoods_annuity_pols +
                            CC_Inputs.countryside_annuity_pols +
                            CC_Inputs.prairie_view_annuity_pols +
                            CC_Inputs.river_ridge_annuity_pols
                        )}
                      </td>
                      {/* Med Supp Prem */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {CurrencyFormatter(
                          CC_Inputs.lakeland_med_prem +
                            CC_Inputs.maple_trail_med_prem +
                            CC_Inputs.northwoods_med_prem +
                            CC_Inputs.countryside_med_prem +
                            CC_Inputs.prairie_view_med_prem +
                            CC_Inputs.river_ridge_med_prem
                        )}
                      </td>
                      {/* Med Supp Pol */}
                      <td className={`px-2 py-4 w-32 font-bold`}>
                        {NumberFormatter(
                          CC_Inputs.lakeland_med_pols +
                            CC_Inputs.maple_trail_med_pols +
                            CC_Inputs.northwoods_med_pols +
                            CC_Inputs.countryside_med_pols +
                            CC_Inputs.prairie_view_med_pols +
                            CC_Inputs.river_ridge_med_pols
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Actions */}
              <div className="flex justify-between my-5">
                {/* Prev and Next */}
                <div className="flex gap-5 justify-between">
                  <div
                    onClick={() => setActive(3)}
                    className="flex items-center justify-center gap-2 w-32 px-3 py-2 rounded-md bg-tertiary hover:bg-opacity-80 text-white cursor-pointer"
                  >
                    <IoMdArrowRoundBack /> Previous
                  </div>
                </div>

                <div className="flex gap-5 justify-between">
                  {load ? (
                    <span className="flex items-center justify-center gap-2 px-5 text-center py-2 rounded-md bg-gray-600 cursor-pointer text-white">
                      <LuLoader2 className="text-xl animate-spin" /> Processing
                    </span>
                  ) : (
                    <input
                      type="submit"
                      onClick={(e) => submitForm(e)}
                      value={"Submit"}
                      className="flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md bg-primary cursor-pointer text-white"
                    />
                  )}
                  <div
                    onClick={() =>
                      showOutput ? setPage("Sales Goal Outputs") : ""
                    }
                    className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white ${
                      showOutput
                        ? "bg-orange-500 cursor-pointer"
                        : "bg-orange-200"
                    }`}
                  >
                    Show Output
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesGoalsInputs;
