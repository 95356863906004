import React, { useEffect, useState } from "react";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter,
} from "../../utils/Utils";
import { CSVLink } from "react-csv";

const tableHead = [
  "2024 Proposed Sales Goal",
  "2024 Contract for Success",
  "Proposed Goal vs. Contract for Success Delta (%)",
];

const CforSComparison = ({ outputData, active }) => {
  const [tableTitle, setTableTitle] = useState(tableHead);
  const [subTableTile, setSubTableTile] = useState(["Premium", "Policies"]);
  const [region, setRegion] = useState([
    "Central",
    "Lakeland",
    "Maple Trail",
    "Northwoods",
    "Southwest",
    "Countryside",
    "Prairie View",
    "River Ridge",
    "Total",
  ]);
  const [products, setProducts] = useState(["Life", "Annuity", "Med Supp"]);

  // Export to CSV states
  const [comparisonLifeHeader, setComparisonLifeHeader] = useState(null);
  const [comparisonLifeData, setComparisonLifeData] = useState(null);
  const [comparisonAnnuityHeader, setComparisonAnnuityHeader] = useState(null);
  const [comparisonAnnuityData, setComparisonAnnuityData] = useState(null);
  const [comparisonMedSuppHeader, setComparisonMedSuppHeader] = useState(null);
  const [comparisonMedSuppData, setComparisonMedSuppData] = useState(null);

  // Assign to states for export
  useEffect(() => {
    // console.log(outputData);
    // comparisonLife
    let comparisonLife = {};
    const life_proposed = Object.entries(
      outputData.proposed_sales_goal_2024
    ).filter(([key, value]) => key.includes("Life"));
    const life_contract = Object.entries(
      outputData.contract_for_success_2024
    ).filter(([key, value]) => key.includes("Life"));
    const life_comparison = Object.entries(
      outputData.contract_for_success_delta
    ).filter(([key, value]) => key.includes("Life"));
    appendHead(life_proposed, tableHead[0]);
    appendHead(life_contract, tableHead[1]);
    appendHead(life_comparison, tableHead[2]);
    comparisonLife = [...life_proposed, ...life_contract, ...life_comparison];
    exportDataCreation(
      comparisonLife,
      setComparisonLifeHeader,
      setComparisonLifeData
    );
    // console.log(comparisonLife);

    // comparisonAnniuty
    let comparisonAnniuty = {};
    const annuity_proposed = Object.entries(
      outputData.proposed_sales_goal_2024
    ).filter(([key, value]) => key.includes("Life"));
    const annuity_contract = Object.entries(
      outputData.contract_for_success_2024
    ).filter(([key, value]) => key.includes("Life"));
    const annuity_comparison = Object.entries(
      outputData.contract_for_success_delta
    ).filter(([key, value]) => key.includes("Life"));
    appendHead(annuity_proposed, tableHead[0]);
    appendHead(annuity_contract, tableHead[1]);
    appendHead(annuity_comparison, tableHead[2]);
    comparisonAnniuty = [
      ...annuity_proposed,
      ...annuity_contract,
      ...annuity_comparison,
    ];
    exportDataCreation(
      comparisonAnniuty,
      setComparisonAnnuityHeader,
      setComparisonAnnuityData
    );
    // console.log(comparisonAnniuty);

    // comparisonMedSupp
    let comparisonMedSupp = {};
    const med_supp_proposed = Object.entries(
      outputData.proposed_sales_goal_2024
    ).filter(([key, value]) => key.includes("Life"));
    const med_supp_contract = Object.entries(
      outputData.contract_for_success_2024
    ).filter(([key, value]) => key.includes("Life"));
    const med_supp_comparison = Object.entries(
      outputData.contract_for_success_delta
    ).filter(([key, value]) => key.includes("Life"));
    appendHead(med_supp_proposed, tableHead[0]);
    appendHead(med_supp_contract, tableHead[1]);
    appendHead(med_supp_comparison, tableHead[2]);
    comparisonMedSupp = [
      ...med_supp_proposed,
      ...med_supp_contract,
      ...med_supp_comparison,
    ];
    exportDataCreation(
      comparisonMedSupp,
      setComparisonMedSuppHeader,
      setComparisonMedSuppData
    );
    // console.log(comparisonMedSupp);
  }, [active]);

  // console.log(comparisonLifeHeader, comparisonLifeData);

  // function to create data
  const exportDataCreation = (object, setCsvHeader, setCsvData) => {
    let csvHeader = [];
    let csvData = [];

    object.forEach((item) => {
      const temp = { label: `${item[0]}`, key: `${item[0]}` };
      csvHeader.push(temp);
    });

    setCsvHeader([{ label: "Region/Team", key: "Region/Team" }, ...csvHeader]);

    region.forEach((reg, id) => {
      let temp = { "Region/Team": `${reg}` };
      for (let index = 0; index < object.length; index++) {
        const element = object[index];
        temp = {
          ...temp,
          [element[0]]: element[0].includes("%")
            ? PercentFormatter(element[1][reg])
            : element[0].includes("Premium")
            ? CurrencyFormatter(element[1][reg])
            : NumberFormatter(element[1][reg]),
        };
      }
      csvData.push(temp);
    });

    setCsvData(csvData);
  };

  // function to append table head to headers
  const appendHead = (array, head) => {
    array.map((item, index) => {
      item[0] = `${head} ${item[0]}`;
      // console.log(item[0]);
    });
  };

  // Assigning color
  function getColor(value, maxValue, minValue, attribute) {
    if (attribute === "Background") {
      if (value < 0) {
        const opacity = Math.abs(value) / Math.abs(minValue);
        return `rgba(255, 0, 0, ${opacity})`; // Red with decreasing opacity
      } else if (value > 0) {
        const opacity = value / maxValue;
        // rgb(155, 207, 83)
        return `rgba(0, 204, 0, ${opacity})`; // Green with increasing opacity
      } else {
        return "transparent"; // White
      }
    } else {
      if (value < 0) {
        const opacity = Math.abs(value) / Math.abs(minValue);
        if (opacity > 0.7) {
          return `white`;
        } else {
          return `#374151`;
        }
      } else if (value > 0) {
        const opacity = value / maxValue;
        if (opacity > 0.7) {
          return `white`;
        } else {
          return `#374151`;
        }
      } else {
        return `#374151`;
      }
    }
  }

  return (
    <div className="w-full text-sm text-gray-700 relative border p-5 rounded-md shadow-md">
      <div className="flex justify-between items-start">
        <h3 className="font-bold mb-5">
          Comparison to Contract for Success Objectives:{" "}
          {active === "Life"
            ? "Life"
            : active === "Annuity"
            ? "Annuity"
            : active === "Med Supp"
            ? "Med Supp"
            : ""}
        </h3>
        {active === "Life" ? (
          <>
            {comparisonLifeData && comparisonLifeHeader && (
              <CSVLink
                data={comparisonLifeData}
                headers={comparisonLifeHeader}
                filename={"Comparison to Contract for Success Objectives: Life"}
                className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
              >
                Export to .csv
              </CSVLink>
            )}
          </>
        ) : active === "Annuity" ? (
          <>
            {comparisonAnnuityData && comparisonAnnuityHeader && (
              <CSVLink
                data={comparisonAnnuityData}
                headers={comparisonAnnuityHeader}
                filename={
                  "Comparison to Contract for Success Objectives: Annuity"
                }
                className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
              >
                Export to .csv
              </CSVLink>
            )}
          </>
        ) : active === "Med Supp" ? (
          <>
            {comparisonMedSuppData && comparisonMedSuppHeader && (
              <CSVLink
                data={comparisonMedSuppData}
                headers={comparisonMedSuppHeader}
                filename={
                  "Comparison to Contract for Success Objectives: Med Supp"
                }
                className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
              >
                Export to .csv
              </CSVLink>
            )}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="border w-full rounded-md overflow-auto">
        <table className="w-full">
          <thead className="">
            <tr className="bg-cyan-800 text-white">
              <th className="p-2 text-left" colSpan={1} rowSpan={2}>
                Region / Team
              </th>
              {tableTitle.map((item, index) => (
                <th
                  className={`p-2 border-l ${
                    item ===
                      "Proposed Sales Goal vs. Contract for Success (%)" && ""
                  } `}
                  colSpan={2}
                  key={item}
                >
                  {item}
                </th>
              ))}
            </tr>
            <tr className="bg-cyan-800 text-white">
              {tableTitle.map((title, index) => (
                <>
                  {subTableTile.map((item) => (
                    <th
                      className={`p-2 border-l border-t ${
                        title ===
                          "Proposed Sales Goal vs. Contract for Success (%)" &&
                        "bg-cyan-800 text-white"
                      }`}
                      key={index + item}
                    >
                      {item}
                    </th>
                  ))}
                </>
              ))}
            </tr>
          </thead>
          <tbody className="text-center ">
            {region.map((team, index) => {
              return (
                <tr
                  key={index}
                  className={`hover:bg-gray-200 ${
                    index % 2 === 0 ? "" : "bg-gray-100"
                  }`}
                >
                  <td
                    className={`w-32 p-2 text-left ${
                      team == "Central" ||
                      team == "Southwest" ||
                      team == "Total"
                        ? "font-bold"
                        : "pl-6"
                    }`}
                  >
                    {team}
                  </td>
                  <td
                    className={`w-32  ${
                      (team == "Central" ||
                        team == "Southwest" ||
                        team == "Total") &&
                      "font-bold"
                    }`}
                  >
                    {CurrencyFormatter(
                      outputData["proposed_sales_goal_2024"][
                        `${active} Premium`
                      ][team]
                    )}
                  </td>
                  <td
                    className={`w-32  ${
                      (team == "Central" ||
                        team == "Southwest" ||
                        team == "Total") &&
                      "font-bold"
                    }`}
                  >
                    {NumberFormatter(
                      Math.ceil(
                        outputData["proposed_sales_goal_2024"][
                          `${active} Policies`
                        ][team]
                      )
                    )}
                  </td>
                  <td
                    className={`w-32  ${
                      (team == "Central" ||
                        team == "Southwest" ||
                        team == "Total") &&
                      "font-bold"
                    }`}
                  >
                    {CurrencyFormatter(
                      outputData["contract_for_success_2024"][
                        `${active} Premium`
                      ][team]
                    )}
                  </td>
                  <td
                    className={`w-32  ${
                      (team == "Central" ||
                        team == "Southwest" ||
                        team == "Total") &&
                      "font-bold"
                    }`}
                  >
                    {NumberFormatter(
                      outputData["contract_for_success_2024"][
                        `${active} Policies`
                      ][team]
                    )}
                  </td>
                  <td
                    className={`w-32  ${
                      (team == "Central" ||
                        team == "Southwest" ||
                        team == "Total") &&
                      "font-bold"
                    }`}
                    style={{
                      backgroundColor: getColor(
                        outputData["contract_for_success_delta"][
                          `${active} Premium`
                        ][team] * 100,
                        outputData["max_contract_delta"] * 100,
                        outputData["min_contract_delta"] * 100,
                        "Background"
                      ),
                      color: getColor(
                        outputData["contract_for_success_delta"][
                          `${active} Premium`
                        ][team] * 100,
                        outputData["max_contract_delta"] * 100,
                        outputData["min_contract_delta"] * 100,
                        "Color"
                      ),
                    }}
                  >
                    {PercentFormatter(
                      outputData["contract_for_success_delta"][
                        `${active} Premium`
                      ][team]
                    )}
                  </td>
                  <td
                    className={`w-32  ${
                      (team == "Central" ||
                        team == "Southwest" ||
                        team == "Total") &&
                      "font-bold"
                    }`}
                    style={{
                      backgroundColor: getColor(
                        outputData["contract_for_success_delta"][
                          `${active} Policies`
                        ][team] * 100,
                        outputData["max_contract_delta"] * 100,
                        outputData["min_contract_delta"] * 100,
                        "Background"
                      ),
                      color: getColor(
                        outputData["contract_for_success_delta"][
                          `${active} Policies`
                        ][team] * 100,
                        outputData["max_contract_delta"] * 100,
                        outputData["min_contract_delta"] * 100,
                        "Color"
                      ),
                    }}
                  >
                    {PercentFormatter(
                      outputData["contract_for_success_delta"][
                        `${active} Policies`
                      ][team]
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CforSComparison;
