import axios from "axios";
// Allow Backend to set cookies on client side.
axios.defaults.withCredentials = true;
// Setup Base URL for API calls
const API = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const API_FILE = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// interceptor for middleware at backend;
// or adding the headers to axios request to backend;
API.interceptors.request.use((req) => {
  if (localStorage.getItem("cuf_user_info")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("cuf_user_info")).accessToken
    }`;
  }

  req.headers.Accept = "*";
  req.headers["Content-Type"] = "application/json";
  req.withCredentials = true;

  return req;
});

// for adding images or files
API_FILE.interceptors.request.use((req) => {
  if (localStorage.getItem("cuf_user_info")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("cuf_user_info")).accessToken
    }`;
  }
  req.headers.Accept = "*";
  req.headers["Content-Type"] = "multipart/form-data";
  req.withCredentials = true;

  return req;
});

export default API;
