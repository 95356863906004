import React, { useEffect, useState } from "react";

const StateTabs = ({ data, state, setState }) => {
  return (
    <>
      {/* overflow-x-auto scrollbar-hide */}
      <div className="flex whitespace-nowrap gap-2 border-b-2 overflow-x-scroll scrollbar-hide border-primary text-gray-500 relative -mt-2">
        {/* If product has modules and deployments */}
        {data?.map((item, id) => {
          return (
            <div
              key={id}
              className={`flex gap-2 items-center cursor-pointer py-2 px-3 w-max rounded-t-md border-transparent  hover:shadow-sm hover:border-gray-300 ease-in-out ${
                state === item.title
                  ? "text-white bg-primary"
                  : "text-gray-400 hover:bg-gray-100 drop-shadow-sm bg-gray-50"
              } `}
              onClick={() => setState(item.title)}
            >
              <span>{item.icon}</span>
              <span className="text-xs font-semibold">{item.title}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StateTabs;
