import React, { useState } from "react";
import Instructions from "./Instructions";
import Assumptions from "./Assumptions";

const InstructionsAssumptions = () => {
  const [active, setActive] = useState("Instructions");
  return (
    <div>
      <div className="flex gap-5 w-full justify-between text-sm text-gray-700 relative">
        {/* Navigations */}
        <div className="w-1/6 p-5 pl-0 border-r font-medium text-xs relative">
          <div className="flex flex-col gap-5 sticky top-24">
            <div
              className={`p-3 border rounded-md text-white hover:shadow-md cursor-pointer ${
                active === "Instructions" ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive("Instructions")}
            >
              Instructions
            </div>
            <div
              className={`p-3 border rounded-md text-white hover:shadow-md cursor-pointer ${
                active === "Assumptions" ? "bg-cyan-800" : "bg-tertiary"
              }`}
              onClick={() => setActive("Assumptions")}
            >
              Assumptions
            </div>
          </div>
        </div>

        <div className="w-5/6 h-full border rounded-md shadow-md p-5">
          {active === "Instructions" && <Instructions />}
          {active === "Assumptions" && <Assumptions />}
        </div>
      </div>
    </div>
  );
};

export default InstructionsAssumptions;
