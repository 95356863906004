import React, { useEffect, useState } from "react";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter,
} from "../../utils/Utils";
import { CSVLink } from "react-csv";

const SalesGoalFactbase = ({ factbaseData }) => {
  const [active, setActive] = useState("sales_premium");
  const [products, setProducts] = useState(["Life", "Annuity", "Med Supp"]);
  const [subTableTile, setSubTableTile] = useState([
    "2021",
    "2022",
    "2023",
    // "3-Year Avg",
  ]);
  const [region, setRegion] = useState([
    "Central",
    "Lakeland",
    "Maple Trail",
    "Northwoods",
    "Southwest",
    "Countryside",
    "Prairie View",
    "River Ridge",
    "Total",
  ]);
  const [cufRegion, setCUFRegion] = useState([
    "Central",
    "Lakeland",
    "Maple Trail",
    "Northwoods",
    "Southwest",
    "Countryside",
    "Prairie View",
    "River Ridge",
    "Total",
    "",
    "Home Office and Others",
  ]);
  const [cufRegion2, setCUFRegion2] = useState([
    "Central",
    "Lakeland",
    "Maple Trail",
    "Northwoods",
    "Southwest",
    "Countryside",
    "Prairie View",
    "River Ridge",
    "Total",
    "Home Office and Others",
  ]);

  const [tableColumn, setTableColumn] = useState([
    "Life 2021",
    "Life 2022",
    "Life 2023",
    // "Life 3-Year Avg",
    "Annuity 2021",
    "Annuity 2022",
    "Annuity 2023",
    // "Annuity 3-Year Avg",
    "Med Supp 2021",
    "Med Supp 2022",
    "Med Supp 2023",
    // "Med Supp 3-Year Avg",
  ]);

  const [inforceColumn, setInforceColumn] = useState([
    "Life Inforce Prem",
    "Life Members",
    "Annuity Inforce Prem",
    "Annuity Members",
    "Med Supp Inforce Prem",
    "Med Supp Members",
  ]);

  const [otherMetricsColumn, setOtherMetricsColumn] = useState([
    "Catholic Population",
    "Catholic Parishes",
    "CUF Historical: Grants Paid",
    "CUF Member Advisors",
  ]);

  // Export to CSV states
  const [salesPremiumHeader, setSalesPremiumHeader] = useState(null);
  const [salesPremiumData, setSalesPremiumData] = useState(null);
  const [salesPoliciesHeader, setSalesPoliciesHeader] = useState(null);
  const [salesPoliciesData, setSalesPoliciesData] = useState(null);
  const [marketPremiumHeader, setMarketPremiumHeader] = useState(null);
  const [marketPremiumData, setMarketPremiumData] = useState(null);
  const [marketPoliciesHeader, setMarketPoliciesHeader] = useState(null);
  const [marketPoliciesData, setMarketPoliciesData] = useState(null);
  const [cufInforceHeader, setCufInforceHeader] = useState(null);
  const [cufInforceData, setCufInforceData] = useState(null);
  const [otherMetricsHeader, setOtherMetricsHeader] = useState(null);
  const [otherMetricsData, setOtherMetricsData] = useState(null);

  // Assign to states for export
  useEffect(() => {
    // historical sales premiunm
    const salesPremium = Object.entries(factbaseData.sales_premium);
    exportDataCreationCUF(
      salesPremium,
      setSalesPremiumHeader,
      setSalesPremiumData
    );

    // historical sales policies
    const salesPolicies = Object.entries(factbaseData.sales_policies);
    exportDataCreationCUF(
      salesPolicies,
      setSalesPoliciesHeader,
      setSalesPoliciesData
    );

    // market premium
    const marketPremium = Object.entries(factbaseData.market_premium);
    exportDataCreation(
      marketPremium,
      setMarketPremiumHeader,
      setMarketPremiumData
    );

    // market policies
    const marketPolicies = Object.entries(factbaseData.market_policies);
    exportDataCreation(
      marketPolicies,
      setMarketPoliciesHeader,
      setMarketPoliciesData
    );

    // cuf Inforce
    const cufInforce = Object.entries(factbaseData.cuf_inforce);
    exportDataCreationCUF(cufInforce, setCufInforceHeader, setCufInforceData);

    // Other metrics
    const otherMetrics = Object.entries(factbaseData.other_metrics);
    exportDataCreation(
      otherMetrics,
      setOtherMetricsHeader,
      setOtherMetricsData
    );
  }, [active]);

  // function to create data
  const exportDataCreationCUF = (object, setCsvHeader, setCsvData) => {
    let csvHeader = [];
    let csvData = [];

    object.forEach((item) => {
      const temp = { label: `${item[0]}`, key: `${item[0]}` };
      csvHeader.push(temp);
    });

    setCsvHeader([{ label: "Region/Team", key: "Region/Team" }, ...csvHeader]);

    cufRegion2.forEach((reg, id) => {
      let temp = { "Region/Team": `${reg}` };
      for (let index = 0; index < object.length; index++) {
        const element = object[index];
        temp = {
          ...temp,
          [element[0]]: NumberFormatter(element[1][reg]),
        };
      }
      csvData.push(temp);
    });

    setCsvData(csvData);
  };

  const exportDataCreation = (object, setCsvHeader, setCsvData) => {
    let csvHeader = [];
    let csvData = [];

    object.forEach((item) => {
      const temp = { label: `${item[0]}`, key: `${item[0]}` };
      csvHeader.push(temp);
    });

    setCsvHeader([{ label: "Region/Team", key: "Region/Team" }, ...csvHeader]);

    region.forEach((reg, id) => {
      let temp = { "Region/Team": `${reg}` };
      for (let index = 0; index < object.length; index++) {
        const element = object[index];
        temp = {
          ...temp,
          [element[0]]: NumberFormatter(element[1][reg]),
        };
      }
      csvData.push(temp);
    });

    setCsvData(csvData);
  };

  // console.log(factbaseData);

  return (
    <div>
      <div className="flex gap-5 w-full justify-between text-sm text-gray-700 relative">
        {/* Navigations */}
        <div className="w-3/12 xl:w-2/12 2xl:w-1/6 p-5 pl-0 border-r font-medium text-[11px] xl:text-xs relative flex flex-col gap-5">
          <div
            className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
              active === "sales_premium" ? "bg-cyan-800" : "bg-tertiary"
            }`}
            onClick={() => setActive("sales_premium")}
          >
            CUF Historical Sales Premium
          </div>
          <div
            className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
              active === "sales_policies" ? "bg-cyan-800" : "bg-tertiary"
            }`}
            onClick={() => setActive("sales_policies")}
          >
            CUF Historical Sales Policies
          </div>
          <div
            className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
              active === "market_premium" ? "bg-cyan-800" : "bg-tertiary"
            }`}
            onClick={() => setActive("market_premium")}
          >
            Market Premium
          </div>
          <div
            className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
              active === "market_policies" ? "bg-cyan-800" : "bg-tertiary"
            }`}
            onClick={() => setActive("market_policies")}
          >
            Market Policies
          </div>
          <div
            className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
              active === "cuf_inforce" ? "bg-cyan-800" : "bg-tertiary"
            }`}
            onClick={() => setActive("cuf_inforce")}
          >
            CUF Inforce
          </div>
          <div
            className={`p-3  rounded-md text-white hover:shadow-md cursor-pointer ${
              active === "other_metrics" ? "bg-cyan-800" : "bg-tertiary"
            }`}
            onClick={() => setActive("other_metrics")}
          >
            Other Metrics
          </div>
        </div>

        <div className="w-9/12 xl:w-10/12 2xl:w-5/6 h-full border rounded-md shadow-md p-5">
          <div className="flex justify-between ">
            <h3 className="font-bold mb-5">
              {active === "sales_premium"
                ? "CUF Historical Sales Premium"
                : active === "sales_policies"
                ? "CUF Historical Sales Policies"
                : active === "market_premium"
                ? "Market Premium"
                : active === "market_policies"
                ? "Market Policies"
                : active === "cuf_inforce"
                ? "CUF Inforce"
                : active === "other_metrics"
                ? "Other Metrics"
                : ""}
            </h3>
            {/* Export to csv */}
            {active === "sales_premium" ? (
              <>
                {salesPremiumData && salesPremiumHeader && (
                  <CSVLink
                    data={salesPremiumData}
                    headers={salesPremiumHeader}
                    filename={"CUF Historical Sales Premium"}
                    className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </>
            ) : active === "sales_policies" ? (
              <>
                {salesPoliciesData && salesPoliciesHeader && (
                  <CSVLink
                    data={salesPoliciesData}
                    headers={salesPoliciesHeader}
                    filename={"CUF Historical Sales Policies"}
                    className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </>
            ) : active === "market_premium" ? (
              <>
                {marketPremiumData && marketPremiumHeader && (
                  <CSVLink
                    data={marketPremiumData}
                    headers={marketPremiumHeader}
                    filename={"Market Premium"}
                    className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </>
            ) : active === "market_policies" ? (
              <>
                {marketPoliciesData && marketPoliciesHeader && (
                  <CSVLink
                    data={marketPoliciesData}
                    headers={marketPoliciesHeader}
                    filename={"Market Policies"}
                    className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </>
            ) : active === "cuf_inforce" ? (
              <>
                {cufInforceData && cufInforceHeader && (
                  <CSVLink
                    data={cufInforceData}
                    headers={cufInforceHeader}
                    filename={"CUF Inforce"}
                    className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </>
            ) : active === "other_metrics" ? (
              <>
                {otherMetricsData && otherMetricsHeader && (
                  <CSVLink
                    data={otherMetricsData}
                    headers={otherMetricsHeader}
                    filename={"Other Metrics"}
                    className="px-4 py-2 -mt-2 mb-2 border rounded-md h-fit text-gray-600 hover:shadow-md transition-all duration-150"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </>
            ) : (
              ""
            )}
          </div>

          {/* Products Related Tables */}
          {(active == "sales_premium" || active == "sales_policies") && (
            <div className="border rounded-md overflow-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-cyan-800 text-white">
                    <th className="p-2 text-left" colSpan={1}></th>
                    {products.map((product, index) => (
                      <th
                        className="p-2 border-l border-r"
                        colSpan={3}
                        key={product}
                      >
                        {product}
                      </th>
                    ))}
                  </tr>
                  <tr className="bg-cyan-800 text-white">
                    <th className="p-2 text-left" colSpan={1}>
                      Region/Team
                    </th>
                    {products.map((product, index) => (
                      <>
                        {subTableTile.map((item) => (
                          <th
                            className="p-2 border border-b-0"
                            key={product + item}
                          >
                            {item}
                          </th>
                        ))}
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cufRegion.map((team, index) => (
                    <tr
                      key={team}
                      className={`hover:bg-gray-200 ${
                        index % 2 === 0 ? "" : "bg-gray-100"
                      }`}
                    >
                      <td
                        className={`p-2 w-32 text-left ${
                          team == "Central" ||
                          team == "Southwest" ||
                          team == "Total"
                            ? "font-bold pl-2"
                            : "pl-6"
                        }`}
                      >
                        {team}
                      </td>
                      {tableColumn.map((column) => (
                        <td
                          className={`border-l p-2 w-32 text-center ${
                            (team == "Central" ||
                              team == "Southwest" ||
                              team == "Total") &&
                            "font-bold"
                          }`}
                        >
                          {active.includes("premium") &&
                          factbaseData[active][column][team] >= 0
                            ? CurrencyFormatter(
                                factbaseData[active][column][team]
                              )
                            : active.includes("policies") &&
                              factbaseData[active][column][team] >= 0
                            ? NumberFormatter(
                                factbaseData[active][column][team]
                              )
                            : ""}
                        </td>
                      ))}
                      {/* <td>{factbaseData[active]}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Products Related Tables */}
          {(active == "market_premium" || active == "market_policies") && (
            <div className="border rounded-md overflow-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-cyan-800 text-white">
                    <th className="p-2 text-left" colSpan={1}></th>
                    {products.map((product, index) => (
                      <th
                        className="p-2 border-l border-r"
                        colSpan={3}
                        key={product}
                      >
                        {product}
                      </th>
                    ))}
                  </tr>
                  <tr className="bg-cyan-800 text-white">
                    <th className="p-2 text-left" colSpan={1}>
                      Region/Team
                    </th>
                    {products.map((product, index) => (
                      <>
                        {subTableTile.map((item) => (
                          <th
                            className="p-2 border border-b-0"
                            key={product + item}
                          >
                            {item}
                          </th>
                        ))}
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {region.map((team, index) => (
                    <tr
                      key={team}
                      className={`hover:bg-gray-200 ${
                        index % 2 === 0 ? "" : "bg-gray-100"
                      }`}
                    >
                      <td
                        className={`p-2 w-32 text-left ${
                          team == "Central" ||
                          team == "Southwest" ||
                          team == "Total"
                            ? "font-bold pl-2"
                            : "pl-6"
                        }`}
                      >
                        {team}
                      </td>
                      {tableColumn.map((column) => (
                        <td
                          className={`border-l p-2 w-32 text-center ${
                            (team == "Central" ||
                              team == "Southwest" ||
                              team == "Total") &&
                            "font-bold"
                          }`}
                        >
                          {active.includes("premium") &&
                          factbaseData[active][column][team] >= 0
                            ? CurrencyFormatter(
                                factbaseData[active][column][team]
                              )
                            : active.includes("policies") &&
                              factbaseData[active][column][team] >= 0
                            ? NumberFormatter(
                                factbaseData[active][column][team]
                              )
                            : ""}
                        </td>
                      ))}
                      {/* <td>{factbaseData[active]}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* CUF Inforce */}
          {active === "cuf_inforce" && (
            <div className="border rounded-md overflow-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-cyan-800 text-white">
                    <th className="p-2" colSpan={1}></th>
                    {products.map((product, index) => (
                      <th
                        className="p-2 border border-t-0"
                        colSpan={2}
                        key={product}
                      >
                        {product}
                      </th>
                    ))}
                  </tr>
                  <tr className="bg-cyan-800 text-white">
                    <th className="p-2 text-left" colSpan={1}>
                      Region/Team
                    </th>
                    {products.map((product, index) => (
                      <>
                        <th
                          className="p-2 border border-b-0"
                          key={product + "Prem"}
                        >
                          Inforce Premium
                        </th>
                        <th
                          className="p-2 border border-b-0"
                          key={product + "Member"}
                        >
                          Members
                        </th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {cufRegion.map((team, index) => (
                    <tr
                      key={region}
                      className={`hover:bg-gray-200 ${
                        index % 2 === 0 ? "" : "bg-gray-100"
                      }`}
                    >
                      <td
                        className={`w-32 text-left ${
                          team == "Central" ||
                          team == "Southwest" ||
                          team == "Total"
                            ? "font-bold pl-2"
                            : "pl-6"
                        } p-2`}
                      >
                        {team}
                      </td>
                      {inforceColumn.map((column, index) => (
                        <td
                          className={`w-32 border-l text-center ${
                            (team == "Central" ||
                              team == "Southwest" ||
                              team == "Total") &&
                            "font-bold"
                          }`}
                        >
                          {column.includes("Prem") &&
                          factbaseData[active][column][team] >= 0
                            ? CurrencyFormatter(
                                factbaseData[active][column][team]
                              )
                            : column.includes("Members") &&
                              factbaseData[active][column][team] >= 0
                            ? NumberFormatter(
                                factbaseData[active][column][team]
                              )
                            : ""}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Other metrics */}
          {active === "other_metrics" && (
            <div className="border rounded-md overflow-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-cyan-800 text-white">
                    <th className="text-left p-2" colSpan={1}>
                      Region/Team
                    </th>
                    {otherMetricsColumn.map((column, index) => (
                      <th className="p-2 border-l" key={column}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {region.map((team, index) => (
                    <tr
                      key={team}
                      className={`hover:bg-gray-200 ${
                        index % 2 === 0 ? "" : "bg-gray-100"
                      }`}
                    >
                      <td
                        className={`w-32 p-2 text-left ${
                          team == "Central" ||
                          team == "Southwest" ||
                          team == "Total"
                            ? "font-bold pl-2"
                            : "pl-6"
                        }`}
                      >
                        {team}
                      </td>
                      {otherMetricsColumn.map((column, index) => (
                        <td
                          className={`w-32 border-l text-center ${
                            (team == "Central" ||
                              team == "Southwest" ||
                              team == "Total") &&
                            "font-bold"
                          }`}
                        >
                          {column.includes("Paid")
                            ? CurrencyFormatter(
                                factbaseData[active][column][team]
                              )
                            : NumberFormatter(
                                factbaseData[active][column][team]
                              )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesGoalFactbase;
