import React from "react";

const Assumptions = () => {
  return (
    <div className="w-full">
      <h2 className="p-4 bg-cyan-800 font-bold text-xl text-white rounded-md">
        Intelligent Goal Setter: Scope, Methodology & Assumptions
      </h2>
      <div className="my-4">
        <h2 className="font-bold text-base">Scope</h2>
        <ul className="list-disc my-2 pl-6 leading-6">
          <li>
            Goals assigned at Region and Team level in current distribution
            structure
          </li>
          <li>
            Products: Life (with SPYT and non-SPYT breakdown), CUF Annuity,
            Medicare Supplemental i.e., Med Supp
          </li>
          <ul className="pl-4">
            <li> NOTE: excludes Supplemental, FIA, FA/LTC</li>
          </ul>
        </ul>
      </div>

      <div className="my-4">
        <h2 className="font-bold text-base">Methodology & Assumptions</h2>
        <ul className="list-disc my-2 pl-6 leading-8">
          <li>
            <p>
              Total Company Goals used as input for Sales Goals (not Contract
              for Success Goals)
            </p>
            <ul className=" pl-4">
              <li>
                {" "}
                Used CUF Annuity premium and app goals only, excludes FIA and
                FA/LTC
              </li>
              <li> NOTE: Sales Goals Premium reflects Annualized Premium</li>
            </ul>
          </li>
          <li>
            <p>
              Incorporates weighted average CUF Historical Sales data for last 3
              years, with ability to adjust weighting of each year
            </p>
            <ul className="list-[numeric] pl-4">
              <li>
                Sold policies refers to total issued policies for in scope
                products, regardless of status or geography
              </li>
              <li>
                Sales premium refers to Paid Premium; Annualized Premium only
                captured since 2022
              </li>
              <li>Sales are then assigned to Teams as follows</li>
              <ul className="list-[disc] pl-6">
                <li>
                  Use policyholder zip code to look up Team from rep-zip
                  assignment file
                </li>
                <li>
                  Use the field 'WritingRepTeam' and 'WritingRepRegion' when the
                  policyholder zip code is not in the rep-zip assignment file
                </li>
                <li>
                  Any remaining sales where the 'WritingRepTeam' is NULL is
                  assigned to Home Office and Others
                </li>
              </ul>
            </ul>
          </li>
          <li>
            Incorporates weighted average Catholic-relevant sales market size
            data for last 3 years, with ability to adjust weighting of each year
          </li>
          <li>
            For products where CUF Historical Sales or Catholic-relevant market
            data is not known, Life data has been used
          </li>
          <li>
            Weighted Goals are initially assigned to each Region/Team using an
            allocation methodology of up to 10 metrics
          </li>
          <li>
            Each metric can be individually weighted to contribute to the sales
            goal allocation, or ignored by setting the value to 0%
          </li>
          <li>
            Member advisor staffing and productivity are used to determine the
            Proposed Sales Goal, which is adjusted based on expected production
          </li>
          <li>
            Life SPYT and Life non-SPYT goals are allocated within Life at the
            end, after Proposed Sales Goal has been established
          </li>
          <li>
            The Contract for Success Objectives supplied by Team Leaders
            excludes New Hire production (not assigned to Teams)
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Assumptions;
